import firebase from "firebase";
import { firebaseAuth } from "@/firebase/firebaseAuth";
import { storeLead, getLead } from "@/services/leads";
import {
  storeUser,
  createAuthUser,
  loginAnonymous,
  getUserByEmail,
  getAuthUser,
} from "@/services/users";
import { getEnviroments } from "@/services/enviroments";
import { getConsumerUnitQualifications } from "@/services/qualifications";
import { firestore } from "../firebase/firestore";
import md5 from "md5";
import { getConfig } from "../services/config";
import { getCoupon } from "../services/coupon";
let repeat_change_password = 0;
export default {
  resetState({ commit }) {
    commit("resetState");
  },
  async getLeadInitial({ commit }, email) {
    let _email = null;
    let locallead = null;
    if (email) _email = email;
    else if (localStorage.getItem("lead")) {
      locallead = JSON.parse(localStorage.getItem("lead"));
      _email = locallead.email;
    }
    if (_email) {
      await getLead(_email)
        .then((lead) => {
          if (lead.exists) {
            let _lead = {
              id: lead.id,
              ...lead.data(),
            };
            commit("setLead", _lead);
          } else {
            commit("setLead", locallead);
          }
        })
        .catch((err) => {
          console.error("Erro ao recuperar o lead!", err);
          commit("setLead", locallead);
        });
    } else {
      commit("setLead", {});
    }
  },

  async getUserInitial({ commit }, email) {
    if (email) {
      const _user = await getUserByEmail(email);
      if (_user) {
        commit("setUser", _user);
        return _user;
      } else throw new Error("Erro ao tentar recuperar usuário.");
    } else if (JSON.parse(localStorage.getItem("user")) != null) {
      const _user = JSON.parse(localStorage.getItem("user"));
      if (_user && _user.email) return _user;
    } else throw new Error("Erro ao tentar recuperar usuário.");
  },

  getLeadById({ commit }, id) {
    commit("setLead", id);
    return id;
  },
  getLocalLead({ commit }) {
    if (localStorage.getItem("lead")) {
      let locallead = JSON.parse(localStorage.getItem("lead"));
      getLead(locallead.email)
        .then((lead) => {
          if (lead.exists) {
            let _lead = {
              id: lead.id,
              ...lead.data(),
            };
            commit("setLead", _lead);
          } else {
            commit("setLead", locallead);
          }
        })
        .catch((err) => {
          console.error("Erro ao recuperar o lead!", err);
          commit("setLead", locallead);
        });
    }
  },
  saveLead({ commit }, lead) {
    if (!lead.email) {
      commit("setLead", lead);
      localStorage.setItem("lead", JSON.stringify(lead));
      return lead;
    }
    return storeLead(lead.email, lead)
      .then((res) => {
        let new_lead = {
          id: res.id,
          ...res.data(),
        };
        commit("setLead", new_lead);
        localStorage.setItem("lead", JSON.stringify(new_lead));
        return new_lead;
      })
      .catch((err) => {
        return err;
      });
  },
  getLocalUser({ commit }) {
    if (localStorage.getItem("user")) {
      commit("setUser", JSON.parse(localStorage.getItem("user")));
    }
  },
  saveUser({ commit }, user) {
    if (!user.id || user.id === user.email) {
      return createAuthUser(user)
        .then(async (authUser) => {
          let id = null;
          if (authUser.code === "auth/email-already-in-use") {
            // Pega usuario existente
            let user_ = await getAuthUser(user);

            if (user_ && user_.user && user_.user.uid) id = user_.user.uid;
            // return authUser
          }
          id = authUser && authUser.user ? authUser.user.uid : id;
          let _token =
            authUser && authUser.user
              ? authUser.user.za
              : localStorage.getItem("token");
          return storeUser(id, user)
            .then((res) => {
              if (res.code || !res.exists) return res;
              let new_user = {
                id: res.id,
                ...res.data(),
              };
              commit("setUser", new_user);
              commit("setToken", _token);
              localStorage.setItem("user", JSON.stringify(new_user));
              localStorage.setItem("token", _token);
              return res;
            })
            .catch((err) => {
              console.error("Error: ", err);
              return err;
            });
        })
        .catch((err) => {
          console.error("Error: ", err);
          return err;
        });
    } else {
      if (!localStorage.getItem("token")) {
        loginAnonymous().catch((err) => console.error(err));
      }
      return storeUser(user.id, user)
        .then((res) => {
          if (res.code) return res;
          let new_user = {
            id: res.id,
            ...res.data(),
          };
          commit("setUser", new_user);
          localStorage.setItem("user", JSON.stringify(new_user));
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  },
  updateUser({ commit }, user) {
    return storeUser(user.id, user.data)
      .then((res) => {
        if (res.code) return res;
        let new_user = {
          id: res.id,
          ...res.data(),
        };
        commit("setUser", new_user);
        localStorage.setItem("user", JSON.stringify(new_user));
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  async login({ commit }, authData) {
    return await firebaseAuth
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then((res) => {
        return res.user.getIdToken().then((token) => {
          commit("setToken", token);
          localStorage.setItem("token", token);
          return res;
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return error;
      });
  },
  async updatePassword({ commit, dispatch, state }, password) {
    let oldPassword = md5(state.user.cpf);
    let cred = firebase.auth.EmailAuthProvider.credential(
      state.user.email,
      oldPassword
    );
    return firebaseAuth.currentUser
      .reauthenticateWithCredential(cred)
      .then((res) => {
        return res.user
          .updatePassword(password)
          .then(() => {
            return firebaseAuth.currentUser.getIdToken().then((token) => {
              commit("setToken", token);
              localStorage.setItem("token", token);
              return token;
            });
          })
          .catch((error) => {
            console.error("Error: ", error);
            if (
              error.code === "auth/requires-recent-login" &&
              repeat_change_password === 0
            ) {
              repeat_change_password = 1;
              let authData = {
                email: state.user.email,
                password: md5(state.user.cpf),
              };
              dispatch("login", authData)
                .then(() => {
                  dispatch("updatePassword", password);
                })
                .catch((err) => {
                  console.error(err);
                  return err;
                });
            }
            return error;
          });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return error;
      });

    // return await firebaseAuth.currentUser.updatePassword(password)
    //   .then(() => {
    //         return firebaseAuth.currentUser.getIdToken().then(token => {
    //             commit('setToken', token)
    //             localStorage.setItem('token', token)
    //             return token
    //         })
    //   })
    //   .catch(error => {
    //     console.error('Error: ', error)
    //     if(error.code === 'auth/requires-recent-login' && repeat_change_password === 0) {
    //         repeat_change_password = 1
    //         let authData = {
    //             email: state.user.email,
    //             password: md5(state.user.cpf)
    //         }
    //         dispatch('login', authData)
    //             .then(() => {
    //                 dispatch('updatePassword', password)
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //                 return err
    //             })
    //     }
    //     return error
    //   })
  },
  async saveProvider(provider) {
    return provider;
  },
  async facebookSignUp({ commit, dispatch, state }) {
    var provider = new firebase.auth.FacebookAuthProvider();
    // provider.addScope('user_birthday');
    // provider.addScope('user_gender');
    // provider.addScope('user_location');
    return (
      firebaseAuth
        // .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // console.log('fb result: ', result)
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;

          // The signed-in user info.
          let lead = {
            ...state.lead,
            name: result.user.displayName,
            email: result.user.email,
            phone: result.user.phoneNumber,
            photoURL: result.user.photoURL,
            id: result.user.uid,
            providerID: "facebook.com",
          };
          return dispatch("saveLead", lead)
            .then((_lead) => {
              let lead = {
                ..._lead,
                id: result.user.uid,
                customer_id: result.user.uid,
              };
              var accessToken = credential.accessToken;
              commit("setSocialToken", accessToken);
              return lead;
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error("errorCode: ", errorCode);
          console.error("errorMessage: ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.error("email: ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.error("credential: ", credential);
          return error;
          // ...
        })
    );
  },
  async googleSignUp({ commit, dispatch, state }) {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    // provider.addScope('user_birthday');
    // provider.addScope('user_gender');
    // provider.addScope('user_location');
    return (
      firebaseAuth
        // .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          let _token = result.user.za;
          commit("setToken", _token);
          localStorage.setItem("token", _token);
          // The signed-in user info.
          let lead = {
            ...state.lead,
            name: result.user.displayName,
            email: result.user.email,
            phone: result.user.phoneNumber,
            photoURL: result.user.photoURL,
            // id: result.user.email,
            providerID: "google.com",
            customer_id: result.user.uid,
          };
          // commit('setLead', lead)
          return dispatch("saveLead", lead)
            .then((_lead) => {
              let lead = {
                ..._lead,
                id: result.user.uid,
                customer_id: result.user.uid,
              };
              var accessToken = credential.accessToken;
              commit("setSocialToken", accessToken);
              return lead;
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error("errorCode: ", errorCode);
          console.error("errorMessage: ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.error("email: ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.error("credential: ", credential);
          return error;
          // ...
        })
    );
  },
  getBenefits({ commit }) {
    getEnviroments()
      .then((enviroments) => {
        commit("setEnviroments", enviroments.data());
      })
      .catch((err) => console.error(err));
  },
  async getDocConfig({ commit }, id) {
    return getConfig(id)
      .then((config) => {
        if (config.exists) commit("setLandingPageConfig", config.data());
        else
          console.error(
            "Não encontrado arquivo de configuração para essa Landing Page"
          );
        return config;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  },
  async getQualification({ state, commit }, cpf) {
    return firestore
      .collection("qualification")
      .where("cpf", "==", cpf)
      .onSnapshot(async (res) => {
        // let qualifications = []

        const changes = res.docChanges();
        // eslint-disable-next-line no-unused-vars
        for (const [idx, change] of changes.entries()) {
          const { doc } = change;
          switch (change.type) {
            case "added": {
              let new_doc = {
                id: doc.id,
                ...doc.data(),
              };
              // let consumer_unit = await firestore.collection('qualification')
              //     .doc(new_doc.id)
              //     .collection('consumer_units')
              //     .doc(new_doc.consumer_unit).get();
              let consumer_unit = await getConsumerUnitQualifications(
                new_doc.id,
                new_doc.consumer_unit
              );

              if (consumer_unit) {
                new_doc.consumer_unit_detail = {
                  id: consumer_unit.id,
                  ...consumer_unit.data(),
                };
              }

              // qualifications.push(new_doc)
              commit("pushQualification", new_doc);
              break;
            }
            case "modified": {
              let new_doc = {
                id: doc.id,
                ...doc.data(),
              };
              // let consumer_unit = await firestore.collection('qualification')
              //     .doc(new_doc.id)
              //     .collection('consumer_units')
              //     .doc(new_doc.consumer_unit).get();
              let consumer_unit = await getConsumerUnitQualifications(
                new_doc.id,
                new_doc.consumer_unit
              );
              if (consumer_unit)
                new_doc.consumer_unit_detail = {
                  id: consumer_unit.id,
                  ...consumer_unit.data(),
                };

              const i = state.qualifications.findIndex((x) => {
                if (x.id) return x.id === doc.id;
              });

              commit("spliceQualification", { index: i, data: new_doc });

              break;
            }
            case "removed": {
              let quali = state.qualifications.filter((i) => i.id !== doc.id);
              commit("setQualifications", quali);
              break;
            }
            default:
              break;
          }
        }
      });
  },
  getCoupons({ commit }, coupon) {
    let today = new Date().toISOString().substring(0, 10);
    commit("setCoupon", null);

    if (!coupon || coupon.trim() === "") return;
    return getCoupon(coupon)
      .then((res) => {
        if (!res || res.size <= 0)
          return {
            status: false,
            message: "Cupom não encontrado ou expirado!",
          };
        let coupon = {
          id: res.docs[0].id,
          ...res.docs[0].data(),
        };
        // Verifica se o limite do cupom já foi atingido
        if (coupon.limit && coupon.limit > 0 && coupon.used >= coupon.limit)
          return { status: false, message: "Cupom excedeu o limite de uso!" };
        // Verifica se a data de inicio do cupom é menor ou igual a hoje
        if (coupon.start_at > today)
          return { status: false, message: "Cupom ainda não está ativo" };
        commit("setCoupon", coupon);
        return { status: res.size > 0, message: coupon.custom_text || "ok" };
      })
      .catch((err) => {
        console.error("e: ", err);
        return { status: false, message: "Erro ao buscar o cupom" };
      });
  },
  deleteCoupon({ commit }) {
    commit("setCoupon", null);
  },
  async getPlans({ commit }, { person_type, plan_id, utility }) {
    let today = new Date();
    commit("setPlans", []);
    let plansRef = firebase.firestore().collection("plans");
    let plans = [];

    if (plan_id) {
      return await plansRef
        .doc(plan_id)
        .get()
        .then(async (res) => {
          if (
            !res.exists ||
            //res.data().status != "activated" ||
            res.data().started_at > today ||
            res.data().person_type != person_type ||
            res?.data()?.company != "juntos"
          )
            return await getRegularPlans();
          else {
            let plan = {
              id: res.id,
              ...res.data(),
            };
            plans.push(plan);
            commit("setPlan", plan);
            commit("setPlans", plans);
          }
        })
        .catch((err) => {
          console.error(err);
          return { status: false, message: "Erro ao buscar o plano" };
        });
    } else return await getRegularPlans();

    async function getRegularPlans() {
      return plansRef
        .where("status", "==", "activated")
        .where("started_at", "<=", today)
        .where("person_type", "==", person_type)
        .where("utility_company", "==", utility || "")
        .where("promotional", "==", false)
        .get()
        .then((res) => {
          if (res.size <= 0) return { status: false, message: "Planos não encontrados!" };
          for (let i = 0; i < res.size; i++) {
            let plan = {
              id: res.docs[i].id,
              ...res.docs[i].data(),
            };
            if (plan?.company == "juntos" && !plans.find(item => item.id == plan.id)) {
              plans.push(plan);
              if (plan.recommended) commit("setPlan", plan);
            } 
            
          }
          commit("setPlans", plans);
          return plans;
        })
        .catch((err) => {
          console.error(err);
          return { status: false, message: "Erro ao buscar o plano" };
        });
    }
  },
  async getPlan({ commit }, id) {
    commit("setPlan", []);
    commit("setPlans", []);

    let plansRef = firebase.firestore().collection("plans");
    return await plansRef
      .doc(id)
      .get()
      .then((res) => {
        if (!res.exists || res?.data()?.company != "juntos")
          return { status: false, message: "Plano não encontrado!" };
        let plan = {
          id: res.id,
          ...res.data(),
        };
        commit("setPlan", plan);
        commit("setPlans", [plan]);
        return plan;
      })
      .catch((err) => {
        console.error(err);
        return { status: false, message: "Erro ao buscar o cupom" };
      });
  },
  async getUtilityCompanies({ commit }) {
    await commit("setUtilityCompanies", []);
    try {
      const utilityCompaniesRef = await firestore
        .collection("utility_companies")
        .get();
      let utilities = [];

      for (const utility of utilityCompaniesRef?.docs) {
        if (utility?.data()?.status == "activated") {
          utilities.push({
            value: utility.data().id,
            text: utility.data().name,
          });
        }
      }

      await commit("setUtilityCompanies", utilities);
    } catch (err) {
      await commit("setUtilityCompanies", []);
      console.error(err);
    }
  },
  async getUc(context, id) {
    try {
      const uc = await firestore
        .collection("consumer_units")
        .doc(id)
        .get();
 
      console.log('uc', uc)
      if (uc.exists == true) {
        return true
      }
      return false
    } catch (err) {
      console.error(err);
      return false;
    }
  },
};
