<template>
  <div class="text-center" v-if="plan">
    <v-dialog
      v-model="dialog_plan_conditions"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          small
        >
          <div class="text-decoration-underline text-none" >
            Entenda como funciona
        </div>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Plano: {{plan ? plan.name : ''}}
        </v-card-title>
        <p class=".subtitle-1 pa-3">Condições</p>
        <v-card-text v-html="plan.conditions"/>

        <!-- <p class=".subtitle-1 pa-3">Regras de Cancelamento</p> -->
        <!-- <v-card-text v-html="plan & plan.cancelation_conditions ? plan.cancelation_conditions.penalty : ''"/> -->
        <v-card-text v-html="penalty"/>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_plan_conditions = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { eventBus } from '../../main'
export default {
    data () {
        return {
            dialog_plan_conditions: false,
        }
    },
    computed: {
        ...mapState(["plan"]),
        penalty() {
          return this.plan && this.plan.cancelation_conditions ? this.plan.cancelation_conditions.penalty : ''
        }
    },
    mounted () {
        eventBus.$on('dialog_plan_conditions', async () => {
            this.dialog_plan_conditions = true
        })
    },
}
</script>