export const getCookie = (cname) => {
    var name = cname + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
}

export const getUTMSorce = (query) => {
    if (query.utm_source || query["amp;utm_source"]) {
      let utm_source = query.utm_source  || query["amp;utm_source"]
      localStorage.setItem("utm_source", utm_source)
      return utm_source
      // } else if (window.$cookies.isKey('utm_source')) {
    } else if (getCookie("utm_source")) {
      let utm_source = getCookie("utm_source")
      localStorage.setItem("utm_source", utm_source)
      return utm_source
    } else if (localStorage.getItem("utm_source")) {
      let utm_source = localStorage.getItem("utm_source")
      return utm_source
    } else {
      return "direct"
    }
}

export const getUTMMedium = (query) => {
    if (query.utm_medium || query["amp;utm_medium"]) {
      let utm_medium = query.utm_medium || query["amp;utm_medium"]
      localStorage.setItem("utm_medium", utm_medium)
      return utm_medium
      // } else if (window.$cookies.isKey('utm_medium')) {
    } else if (getCookie("utm_medium")) {
      let utm_medium = getCookie("utm_medium")
      localStorage.setItem("utm_medium", utm_medium)
      return utm_medium
    } else if (localStorage.getItem("utm_medium")) {
      let utm_medium = localStorage.getItem("utm_medium")
      return utm_medium
    } else {
      return "direct"
    }
}

export const getUTMCampaign = (query) => {
    if (query.utm_campaign || query["amp;utm_campaign"]) {
      let utm_campaign = query.utm_campaign || query["amp;utm_campaign"]
      localStorage.setItem("utm_campaign", utm_campaign)
      return utm_campaign
      // } else if (window.$cookies.isKey('utm_campaign')) {
    } else if (getCookie("utm_campaign")) {
      let utm_campaign = getCookie("utm_campaign")
      localStorage.setItem("utm_campaign", utm_campaign)
      return utm_campaign
    } else if (localStorage.getItem("utm_campaign")) {
      let utm_campaign = localStorage.getItem("utm_campaign")
      return utm_campaign
    } else {
      return "direct"
    }
}

export const getUTMContent = (query) => {
    if (query.utm_content || query["amp;utm_content"]) {
      let utm_content = query.utm_content || query["amp;utm_content"]
      localStorage.setItem("utm_content", utm_content)
      return utm_content
      // } else if (window.$cookies.isKey('utm_content')) {
    } else if (getCookie("utm_content")) {
      let utm_content = getCookie("utm_content")
      localStorage.setItem("utm_content", utm_content)
      return utm_content
    } else if (localStorage.getItem("utm_content")) {
      let utm_content = localStorage.getItem("utm_content")
      return utm_content
    } else {
      return "direct"
    }
}

export const getUTMTerm = (query) => {
    if (query.utm_term || query["amp;utm_term"]) {
      let utm_term = query.utm_term || query["amp;utm_term"]
      localStorage.setItem("utm_term", utm_term)
      return utm_term
      // } else if (window.$cookies.isKey('utm_term')) {
    } else if (getCookie("utm_term")) {
      let utm_term = getCookie("utm_term")
      localStorage.setItem("utm_term", utm_term)
      return utm_term
    } else if (localStorage.getItem("utm_term")) {
      let utm_term = localStorage.getItem("utm_term")
      return utm_term
    } else {
      return "direct"
    }
}

export const getUTMSourcePlatform = (query) => {
  if (query.utm_source_platform || query["amp;utm_source_platform"]) {
    let utm_source_platform = query.utm_source_platform || query["amp;utm_source_platform"]
    localStorage.setItem("utm_source_platform", utm_source_platform)
    return utm_source_platform
    // } else if (window.$cookies.isKey('utm_source_platform')) {
  } else if (getCookie("utm_source_platform")) {
    let utm_source_platform = getCookie("utm_source_platform")
    localStorage.setItem("utm_source_platform", utm_source_platform)
    return utm_source_platform
  } else if (localStorage.getItem("utm_source_platform")) {
    let utm_source_platform = localStorage.getItem("utm_source_platform")
    return utm_source_platform
  } else {
    return "direct"
  }
}

export const getUTMCreativeFormat = (query) => {
  if (query.utm_creative_format || query["amp;utm_creative_format"]) {
    let utm_creative_format = query.utm_creative_format || query["amp;utm_creative_format"]
    localStorage.setItem("utm_creative_format", utm_creative_format)
    return utm_creative_format
    // } else if (window.$cookies.isKey('utm_creative_format')) {
  } else if (getCookie("utm_creative_format")) {
    let utm_creative_format = getCookie("utm_creative_format")
    localStorage.setItem("utm_creative_format", utm_creative_format)
    return utm_creative_format
  } else if (localStorage.getItem("utm_creative_format")) {
    let utm_creative_format = localStorage.getItem("utm_creative_format")
    return utm_creative_format
  } else {
    return "direct"
  }
}

export const getUTMMarketingTactic = (query) => {
  if (query.utm_marketing_tactic || query["amp;utm_marketing_tactic"]) {
    let utm_marketing_tactic = query.utm_marketing_tactic || query["amp;utm_marketing_tactic"]
    localStorage.setItem("utm_marketing_tactic", utm_marketing_tactic)
    return utm_marketing_tactic
    // } else if (window.$cookies.isKey('utm_marketing_tactic')) {
  } else if (getCookie("utm_marketing_tactic")) {
    let utm_marketing_tactic = getCookie("utm_marketing_tactic")
    localStorage.setItem("utm_marketing_tactic", utm_marketing_tactic)
    return utm_marketing_tactic
  } else if (localStorage.getItem("utm_marketing_tactic")) {
    let utm_marketing_tactic = localStorage.getItem("utm_marketing_tactic")
    return utm_marketing_tactic
  } else {
    return "direct"
  }
}

export const getUTM = (query) => {
    let utm = {
        utm_source: getUTMSorce(query),
        utm_medium: getUTMMedium(query),
        utm_campaign: getUTMCampaign(query),
        utm_content: getUTMContent(query),
        utm_term: getUTMTerm(query),
        utm_source_platform: getUTMSourcePlatform(query),
        utm_creative_format: getUTMCreativeFormat(query),
        utm_marketing_tactic: getUTMMarketingTactic(query),
    };
    return utm
}


export const getTracking = (query) => {
    let utm = {
        utm_source: query.utm_source || query["amp;utm_source"] ||  "direct", 
        utm_medium: query.utm_medium || query["amp;utm_medium"] || "direct",
        utm_campaign: query.utm_campaign || query["amp;utm_campaign"] || "direct",
        utm_content: query.utm_content || query["amp;utm_content"] || "direct",
        utm_term: query.utm_term || query["amp;utm_term"] || "direct",
        utm_source_platform: query.utm_source_platform || query["amp;utm_source_platform"] || "direct",
        utm_creative_format: query.utm_creative_format || query["amp;utm_creative_format"] || "direct",
        utm_marketing_tactic: query.utm_marketing_tactic || query["amp;utm_marketing_tactic"] || "direct",
        
    };
    let _tracking = JSON.parse(localStorage.getItem('tracking'))
    let tracking = []
    if(_tracking) tracking = _tracking
    tracking.push({...utm, created_at: new Date()})
    localStorage.setItem('tracking', JSON.stringify(tracking))
    return tracking
}

