import { firestore } from "../firebase/firestore"
import { firestorage } from "../firebase/firestorage"
import { updateLead } from "../services/leads"
import store from "../store"

export const getQualifications = (cpf) => {
    return firestore.collection('qualification').where('cpf', '==', cpf)
};

export const getConsumerUnitQualifications = (id, uc_id) => {
    return firestore.collection('qualification').doc(id).collection('consumer_units').doc(uc_id).get()
};

export const updatedQualification = (id, data) => {
    return firestore.collection('qualification').doc(id).update(data)
}

export const sendBill = (hasQualification, id, file, lead) => {
    var storageRef = firestorage.ref();

    // Create a reference to 'mountains.jpg'
    let date = new Date().toISOString()
    let ext = getFileNameExt(file.name)
    let path = 'uploads/' + lead.customer_id + '/bill_' + date + ext
    
    var fileRef = storageRef.child(path);

    return fileRef.put(file).then(async (snapshot) => {
        let fullPath = snapshot.ref.fullPath
        let data = {
            bill_link: fullPath
        }
        //Se possui qualificação atualiza a qualificação
        if(hasQualification) updatedQualification(id, data)
        
        let bill_links = lead.bill_links || []
        let data_lead
        if(hasQualification) {
            data_lead = {
                link: fullPath,
                qualification_id: id,
                // Adicionar UC
                // consumer_unit: 
            }
        } else {
            data_lead = {
                link: fullPath,
                qualification_id: null
            }
        }
        
        bill_links.push(data_lead)
        let _lead = {
            ...lead,
            bill_links
        }

        await store.dispatch('saveLead', _lead)
        let _user = {
            id: _lead.customer_id,
            data: { bill_links }
        }
        await store.dispatch('updateUser', _user)
        // updateLead(lead.email, { bill_links } )
    });
}

export const deleteBill = (hasQualification, id, url, lead, bill_links) => {
    var storageRef = firestorage.ref();

    // Create a reference to 'mountains.jpg'
    var fileRef = storageRef.child(url.fullPath);
    return fileRef.delete().then(function() {
        if(hasQualification) {
            let data = {
                bill_link: firestore.FieldValue.delete()
            }
            //Se possui qualificação atualiza a qualificação
            updatedQualification(id, data)
        }
        updateLead(lead.email, { bill_links } )
    });
}

export const getFileNameExt = (name) => {
  
    const lastDot = name.lastIndexOf('.');
  
    // const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
  
    return '.' + ext;
}