<template>
    <v-container fluid class="ma-0 container">
        <v-row wrap>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <!-- <v-img src="@/assets/usinas/5.jpg" width="100%" height="200px"/> -->
                <steps :step="2" :total="5" :itens="[(lead.installations_found ? 'Selecionar imóveis' : 'Conta de Luz'), 'Formas de Pagamento']"/>
                <v-container class="pa-3">
                    <h3 class="text-center pt-3 px-2">
                        Anexe sua conta de luz para agilizar o processo de qualificação
                    </h3>
                    <v-row class="px-8 my-5">
                        <v-btn
                            class="mx-0 mb-0 py-11 font-weight-light rounded-xl"
                            min-width="100%"
                            color="#E9E5E5"
                            @click="pickIdEnergyBill"
                            :disabled="loadingParse"
                        >
                            <div class="d-flex flex-column align-center">
                                <v-progress-circular v-if="loadingParse" indeterminate color="primary" class="mb-3"></v-progress-circular>
                                <v-icon
                                    v-else
                                    size="40"
                                    color="primary"
                                    aria-hidden="true"
                                >
                                    mdi-cloud-upload-outline
                                </v-icon>
                                <span v-if="consumerUnits.length < 1" class="caption ml-4">Adicionar 2º via da conta de luz em .pdf</span>
                                <span v-else class="caption ml-4">Adicionar outra Unidade Consumidora</span>
                            </div>
                        </v-btn>
                        <input
                            type="file"
                            ref="idEnergyBillInput"
                            style="display: none"
                            @change="pickedEnergyBill($event)"
                            accept="application/pdf"
                            :key="inputKey"
                        />
                    </v-row>
                    <div class="text-container" v-if="consumerUnits.length > 0">
                        <p class="py-1 text-weight-bold text subtitulo">Unidade consumidora selecionada: </p>
                    </div>
                    <div v-else class="text-container">
                        <p class="mb-0 py-0 pb-6 pt-2 text-weight-bold text subtitulo">Ou se preferir, faça isso depois através <br/> do nosso canal de atendimento</p>
                    </div>
                    <div>
                        <div class="uc mx-5 my-4" v-for="(uc, index) in consumerUnits" :key="index">
                            <div class="uc-data">
                                <p class="font-weight-bold title">{{ uc.installation_number }}</p>
                                <p class="body-2"> {{ address(uc.address) }}</p>
                            </div>
                            <a @click="removeUc(index)" class="black--text ml-4 trash-btn"
                                >
                                <v-icon size="20" color="red" aria-hidden="true">
                                mdi-trash-can-outline
                                </v-icon></a
                            >
                        </div>
                    </div>
                    <v-btn
                        color="secondary"
                        block
                        rounded
                        large
                        :loading="loadingBill"
                        @click.prevent="nextStep"
                        class="text-none"
                        :disabled="loadingParse"
                        >Continuar
                    </v-btn>
                    <h4 class="py-2 text-center" v-if="lead.utility">
                        <a
                            class="text-subtitle-2 black--text"
                            :href="utilityLink"
                            target="_blank"
                            >Obter segunda via da conta de luz da {{ utilityName }}</a
                        >
                    </h4>

                </v-container>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
        <v-snackbar :color="color" top v-model="snackbar" dark>
            <span class="mr-auto">{{ snackMessage }}</span>
            <v-icon size="16" @click="snackbar = false" class="ml-3"> mdi-close-circle </v-icon>
        </v-snackbar>
    </v-container>
</template>
<script>
import Steps from '../components/Steps'
import { mapState, mapActions, mapMutations } from 'vuex'
import { getQualification } from '../services/cemig'
import { convertRDStation } from '../services/rdstation'
import { loginAnonymous } from '../services/users'
import { firestore } from "../firebase/firestore"
import firebase from 'firebase'
import { uploadInvoice } from "../services/parseBill"
import { initQualification } from "../services/qualification"
import statesfile from '@/model/state'

export default {
    components: {
        Steps
    },
    async mounted() {
        if(!localStorage.getItem('token')) {
            await loginAnonymous()
                .then((res) => {
                    let token = res.user.za
                    if(token) {
                        localStorage.setItem('token', token)
                        this.$store.commit("setToken", token)
                    }
                })
                .catch(err => console.error(err))
        }

        if(!this.user) {
            if(localStorage.getItem('user')) {
                this.setUser(localStorage.getItem('user'))
            } else {
                if(localStorage.getItem('lead')) {
                    this.setUser(localStorage.getItem('lead'))
                }
            }
        }

        if(!this.user) {
            this.$router.push({path: '/', query: this.$route.query})
            return null
        }

        if(this.user) {
            if(this.user.installations_found) {
                this.ucs_cemig = this.user.installations_found
                this.$gtm.trackEvent({
                    event: 'step3_imovel_encontrado',
                    category: "onboard",
                    action: "pageview",
                    label: "Imovel encontrado",
                    value: this.user?.average_consumption,
                    noninteraction: false, // Optional
                    step: this.$route.name,
                    person_type: this.user?.person_type,
                    email: this.user?.email,
                    phone: this.user?.phone,
                });
                convertRDStation('step3_imovel_encontrado', this.user)
                    .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
                    .then(() => console.log('Conversão step3_imovel_encontrado gravada com sucesso no RD Station'))
            } else {
                this.$gtm.trackEvent({
                    event: 'step3_imovel_nao_encontrado',
                    category: "onboard",
                    action: "pageview",
                    label: "Imovel nao encontrado",
                    value: this.user?.average_consumption,
                    noninteraction: false, // Optional
                    step: this.$route.name,
                    person_type: this.user?.person_type,
                    email: this.user?.email,
                    phone: this.user?.phone,
                });
                convertRDStation('step3_imovel_nao_encontrado', this.user)
                    .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
                    .then(() => console.log('Conversão step3_imovel_nao_encontrado gravada com sucesso no RD Station'))
            }
        }
    },
    data: () => ({
        ucs_cemig: [],
        settings: null,
        label_loading_cpf: false,
        loading_cpf: false,
        ucs_chbox: [],
        items: [],
        valid: false,
        active: false,
        uc: null,
        ucRules: [
            v => !!v || 'Ops! Por favor, informe a Unidade Consumidora.',
            v => (v && v.length >= 10) || 'Informe a Unidade Consumidora correto.'
        ],
        idEnergyBill: [
            {
                name: null,
                data: null,
            },
        ],
        utilities_links: [
            {
                utility: "cemig",
                name: "CEMIG",
                url: "https://atende.cemig.com.br/Login"
            },
            {
                utility: "neoenergia-pe",
                name: "Neoenergia Pernambuco",
                url: "https://clientescorporativos.neoenergiapernambuco.com.br/_layouts/PCC/login.aspx?ReturnUrl=%2fservicos%2fformularios%2f_layouts%2fAuthenticate.aspx%3fSource%3d%252Fservicos%252Fformularios%252FPaginas%252F2%252Dvia%252Dda%252Dfatura%252Easpx&Source=%2Fservicos%2Fformularios%2FPaginas%2F2%2Dvia%2Dda%2Dfatura%2Easpx"
            },
            {
                utility: "neoenergia-ba",
                name: "Neoenergia Bahia",
                url: "https://servicos.neoenergiacoelba.com.br/area-logada/Paginas/login.aspx?ref=https://servicos.neoenergiacoelba.com.br/servicos-ao-cliente/Pages/2-via-de-conta-coelba.aspx"
            },
            {
                utility: "cpfl-piratininga",
                name: "CPFL Piratininga",
                url: "https://cpflb2cprd.b2clogin.com/cpflb2cprd.onmicrosoft.com/b2c_1a_signup_signin_mfa_front/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN_MFA_FRONT&client_id=17d5831d-6741-4670-8085-d1d34e37aec1&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.cpfl.com.br%2Fb2c-auth%2Freceive-token&scope=17d5831d-6741-4670-8085-d1d34e37aec1%20offline_access&response_type=code&prompt=login&response_mode=query"
            }
        ],
        loadingBill: false,
        color: '',
        snackMessage: '',
        snackbar: false,
        states: statesfile.estados,
        loadingParse: false,
        inputKey: 0
    }),
    computed: {
        ...mapState(['lead', 'user', 'consumerUnits']),
        utilityLink() {
            const utility = this.utilities_links.find(item => item.utility == this.user?.utility)
            return utility?.url ? utility?.url : ""
        },
        utilityName() {
            const utility = this.utilities_links.find(item => item.utility == this.user?.utility)
            return utility?.name ? utility?.name : ""
        }
    },
    methods: {
        ...mapActions(['saveUser', 'saveLead', 'getUserInitial', 'getUc']),
        ...mapMutations(['pushConsumerUnit', 'removeConsumerUnit', 'setUserAddress', 'setUser']),
        async submit() {
            let _user = this.lead
            _user.installation_numbers = this.ucs_chbox

            let data = {
                cpf: _user.cpf, 
                origin: "web", 
                consumer_units: _user.installation_numbers,
                customer_id: _user.customer_id
            }

            let _lead = {
                ...this.lead,
                installation_numbers: this.ucs_chbox,
                step: 'UnidadesConsumidoras'
            }
            this.saveLead(_lead)

            this.startQualification(data, _lead)

            await this.saveUser(_user)
                .then(() => {
                    this.$router.push({path: '/metodos-pagamento', query: this.$route.query})
                })
                .catch(err => console.error(err))
            
        },
        async submitUC() {
            let _user = this.lead
            let ucs_chbox = [this.uc]
            _user.installation_numbers = ucs_chbox

            let data = {
                cpf: _user.cpf, 
                origin: "web", 
                consumer_units: [this.uc],
                customer_id: _user.customer_id
            }
            let _lead = {
                ...this.lead,
                installation_numbers: _user.installation_numbers,
                step: 'UnidadesConsumidoras'
            }
            this.saveLead(_lead)

            this.startQualification(data, _lead)

            await this.saveUser(_user)
                .then(() => {
                    this.$router.push({path: '/metodos-pagamento', query: this.$route.query})
                })
                .catch(err => console.error(err))
            
        },
        selectUC(item) {
            let hasItem = this.ucs_chbox.filter((value) => {
                return value === item
            })
            if(hasItem.length === 0) this.ucs_chbox.push(item)
            else {
                this.ucs_chbox = this.ucs_chbox.filter((value) => {
                    return value !== item
                })
            }
        },
        startQualification(data, lead) {
            getQualification(data)
                .then((res) => {
                    //Salva dados no banco qualification_ids (Array de ids)
                    if(res.status === 200) {
                        let _lead = {
                            ...lead,
                            qualification_ids: res.data.ids,
                            step: this.$route.name
                        }
                        this.saveLead(_lead)
                            .catch(err => console.error('Erro ao salvar lead. ', err))
                    } else {
                        console.error(JSON.stringify(res))
                    }
                })
                .catch(err => console.error(err))
        },
        async nextStep () {
            this.loadingBill = true
            let user = {
                ...this.user,
                step: 'UnidadesConsumidoras',
                status: 'ONBOARDING'
            }

            await this.saveLead(user)
            .then(async () => {
                await this.saveUser(user)
                .then(async () => {
                    await this.createQualification()
                    await this.uploadEnergyBillData()
                })
                .catch(err => {
                    this.snack('Erro ao salvar usuário.', 'red')
                    this.loadingBill = false
                    console.error(err)
                })
            })
            .catch(error => {
                this.snack('Erro ao salvar usuário.', 'red')
                this.loadingBill = false
                console.error(error)
            })
        },
        pickIdEnergyBill () {
            this.$refs.idEnergyBillInput.click()
        },
        async pickedEnergyBill (event) {
            try {
                this.loadingParse = true
                const energyBillFiles = event.target.files
    
                // parse da conta
                const pushDoc = await this.detectFiles(energyBillFiles)
    
                if (pushDoc) {
                    this.idEnergyBill.push({
                        name: energyBillFiles[0].name,
                        data: energyBillFiles[0]
                    })
                }
            } catch (error) {
                console.error(error)
            }
            this.inputKey++
            this.loadingParse = false
        },
        async detectFiles (fileList) {
            var formData = new FormData()
            formData.append('invoice', fileList[0])
            formData.append('utility_company', this.user?.utility)
            return await this.uploadInv(formData)
            .then(async res => {
                if (this.consumerUnits.find(item => item?.installation_number === res.data.numero_instalacao)) {
                    this.snack('Esta Unidade Consumidora já foi adicionada a lista.', 'red')
                    return false
                }

                const ucFound = await firestore.collection("consumer_units").doc(res.data.numero_instalacao).get();
                if (ucFound.exists) {
                    this.snack('Esta Unidade Consumidora já foi cadastrada antes.', 'red')
                    return false
                }

                let tarifa_energia = 0
                let _history = []
                if (res.data.historico) {
                    _history = res.data.historico.map(h => {
                        return {
                            mes_ano: h.competencia,
                            consumo: h.consumo,
                            dias: h.dias
                        }
                    })
                }
                if (this.user?.utility === 'cemig') {
                    const energia_eletrica = res.data.valores_faturados.find(t =>
                        t.descricao.includes('Energia Elétrica')
                    )
                    tarifa_energia = energia_eletrica?.tarifa_preco || 0
                } else {
                    const tusdRgx = /Consumo(\s*([A-Za-z]*\s*)*)(\[KWh\])*-TUSD/
                    const teRgx = /Consumo\s*-\s*TE/
                    const consumo_tusd = res.data.valores_faturados.find(t =>
                        tusdRgx.test(t.descricao)
                    )
                    const consumo_te = res.data.valores_faturados.find(t =>
                        teRgx.test(t.descricao)
                    )
                    tarifa_energia =
                        consumo_tusd.tarifa_preco + consumo_te.tarifa_preco
                }

                let street = res.data.endereco?.rua
                let number = ''
                let complement = ''

                if (
                    ['cemig', 'neoenergia-pe'].includes(
                        this.user?.utility
                    )
                ) {
                    street = res.data.endereco?.rua?.match(/^([A-Z]+\s)+[A-Z]+/)[0]
                    number = res.data.endereco?.rua?.match(/\d+/)[0]
                    complement = res.data.endereco?.rua?.match(/\d+(.+)/)[1]?.trim()
                }

                if (street?.startsWith('ENDEREÇO: ')) {
                    street = street?.slice('ENDEREÇO: '?.length)
                }

                const uc = {
                    status: 'awaiting_contract_generation',
                    constract_type: 'internal',
                    installation_number: res.data.numero_instalacao,
                    customer_number: res.data.numero_cliente,
                    utility_company: this.user?.utility,
                    cpf: this?.user?.cpf,
                    fare_amount: tarifa_energia,
                    rate_class: this.mapClasse(res.data.classe),
                    sub_class: this.mapSubClasse(res.data.subclasse),
                    history: _history,
                    name: res.data.nome,
                    average: 0,
                    percentage_discount: 13,
                    creditos: 0,
                    mensalidade: 0,
                    economia_mensal: 0,
                    economia_anual: 0,
                    economia_contrato: 0,
                    address: {
                        address: street,
                        address_number: number,
                        address_complement: complement,
                        district: res.data.endereco.bairro,
                        postal_code: res.data.endereco.cep,
                        state: this?.states?.filter(item => item.initials === res.data.endereco.estado)[0],
                        city: res.data.endereco.cidade,
                        country: {
                            name: 'Brasil',
                            initials: 'BR'
                        }
                    },
                }

                uc.plan = this.user?.plan
                uc.percentage_discount = this.user?.plan?.discount || null
                if (this.user?.coupon) uc.coupon = this.user.coupon 

                if (res.data.cpf || res.data.cnpj) {
                    uc.cpf = res.data?.cpf || res.data?.cnpj
                    uc.person_type = uc?.cpf?.length > 11 ? 'pj' : 'pf'
                }

                this.setUserAddress(uc.address)
                this.pushConsumerUnit(uc)
                return true
            })
            .catch(error => {
                console.error(error)
                this.snack('Erro ao preencher dados a partir da conta.', 'red')
                return false
            })
        },
        async uploadInv(formData) {
            return await uploadInvoice(formData)
        },
        removeUc (index) {
            this.removeConsumerUnit(index)
            this.idEnergyBill.splice(index, 1)
        },
        async createQualification() {
            this.loading_qualification = true

            for (const consumer_unit of this.consumerUnits) {
                let form_data = {
                    utility_company: consumer_unit.utility_company,
                    name: consumer_unit.name,
                    address: consumer_unit.address,
                    cpf: consumer_unit.cpf,
                    customer_number: consumer_unit.customer_number,
                    consumer_unit: consumer_unit.installation_number,
                    rate_class: consumer_unit.rate_class,
                    sub_class: consumer_unit.sub_class,
                    fare_amount: consumer_unit.fare_amount,
                    history: consumer_unit.history,
                    verify_debits: false,
                    verify_rules: true,
                }
                await initQualification(
                    consumer_unit.cpf,
                    this.user?.customer_id,
                    [consumer_unit.installation_number],
                    [consumer_unit.customer_number],
                    "web",
                    form_data,
                    consumer_unit.utility_company,
                    null,
                    this.user?.email,
                    null
                )
                .then(async (res) => {
                    if (res.status != 200) {
                        console.error(res)
                        this.snack("Erro ao iniciar qualificação.", "red")
                    }
                })
                .catch((err) => {
                    console.error(err)
                    this.snack("Erro ao iniciar qualificação.", "red")
                })
            }

            this.loading_qualification = false
        },
        address(address) {
            let _address = `${address?.address}, ${address?.address_number}`
            if (address.address_complement) {
                _address += " - "  + address.address_complement
            }
            _address += `, ${address?.district}, ${address?.city} - ${address?.state?.initials} - CEP: ${address?.postal_code}`
            return _address
        },
        mapClasse(value) {
            const _value = value.toLowerCase()
            if (_value.includes("monofásico")) return "Monofásico"
            if (_value.includes("bifásico")) return "Bifásico"
            if (_value.includes("trifásico")) return "Trifásico"
            return value
        },

        mapSubClasse(value) {
            const _value = value.toLowerCase()
            if (_value.includes("comercial")) return "Comercial"
            if (_value.includes("residencial")) return "Residencial"
            return value
        },
        removeEnergyBill () {
            this.idEnergyBill = [
                {
                    name: null,
                    data: null
                }
            ]
        },
        async uploadEnergyBillData () {
            if (this.idEnergyBill && this.user.id) {
                try {
                    for (const file of this.idEnergyBill) {
                        let filePath = `users/${this.user?.id}/document_photo/${file.name}`
                        let uploadFile = firebase
                            .storage()
                            .ref()
                            .child(filePath)
                            .put(file.data)
    
                        uploadFile.on(
                            'state_changed',
                            s => {
                            console.log((s.bytesTransferred / s.totalBytes) * 100 + '%')
                            },
                            error => {
                            console.error(error)
                            throw new Error('Falha ao realizar upload de documentos.')
                            },
                            () => {
                            uploadFile.snapshot.ref.getDownloadURL().then(downloadURL => {
                                firestore
                                .collection('users')
                                .doc(this.user.id)
                                .collection('document_photo')
                                .doc(file.name)
                                .set({
                                    name: file.name,
                                    url: downloadURL,
                                    agent_email: this.user?.email,
                                    upload_at: new Date(),
                                    type: 'energy_bill'
                                })
                            })
                            }
                        )
                    }
                } catch (error) {
                    console.error(error)
                }
            } 
            this.loadingBill = false
            this.$router.push({ path: '/metodos-pagamento', query: this.$route.query })
        },
        snack (message, color) {
            this.color = color
            this.snackMessage = message
            this.snackbar = true
        },
    }
}
</script>
<style scoped lang="scss">
.text-container {
  display: flex;
  justify-content: center;
}
.select_uc {
  border-radius: 80px !important;
}
.subtitulo {
  position: relative;
  display: inline-block;
  color: #7c3dff;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.uc {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e3ebf6;
    padding: 10px 20px;
    border-radius: 10px;
    .uc-data {
        display: flex;
        flex-direction: column;
        gap: 6px;
        p {
            margin: 0;
        }
    }
    .trash-btn {
        padding: 6px 8px;
        background-color: #fad3d3;
        border-radius: 30px;
        transition: .4s;
        &:hover {
            background-color: #f7bcbc;
        }
    }
}
.container {
    max-height: 100% !important;
}
.btn-continue {
    background-color: red;
    margin-top: 50px !important;
}
</style>