import api from '../utils/api'

const config = {
  headers: {
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'multipart/form-data'
  }
}

export const uploadInvoice = (formData) => {
  const conta = api.post(process.env.VUE_APP_UPLOADINVOICE_URL, formData, config)
  return conta
}