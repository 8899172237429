<template>
    <v-dialog
        v-model="dialog_howworks"
        :fullscreen="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        max-width="500"
    >
        <!-- <v-layout row class="pa-3"> -->
            <v-container>
                <v-row justify="space-around">
                    <v-card>
                        <v-img
                            height="100vh"
                            src="@/assets/usinas/5.jpg"
                            width="100%"
                            aspect-ratio="2"
                            gradient="to top right, rgba(100,115,201,.25), rgba(25,32,72,.98)"
                        >
                            <v-app-bar
                                flat
                                color="rgba(0, 0, 0, 0)"
                            >
                                <!-- <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon> -->

                                <!-- <v-toolbar-title class="title white--text pl-0">
                                Messages
                                </v-toolbar-title> -->

                                <v-spacer></v-spacer>
                                <v-btn
                                    dark
                                    text
                                    @click="dialog_howworks = false"
                                    >
                                        <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    color="white"
                                    icon
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn> -->
                            </v-app-bar>
                            <!-- <v-card-title class="white--text mt-8">
                                <h2 class="font-weight-light display-1">Energia limpa e economia de forma prática, digital e acessível!</h2>
                            </v-card-title> -->
                            <v-img class="ml-10 mt-16" src="@/assets/novo/logo_icon_branco.png" max-width="50px"/>
                            <div class="d-flex align-end">
                                <v-card-text class="white--text mt-1 display-1 align-end" style="padding:100px 20px 20px 20px">
                                    <div class="display-1 align-end">Migre sua conta de luz para a Juntos Energia</div>
                                    <div class="font-weight-light white--text headline pt-6">É diferente, é economico e é inteligente. Inteligente porque é legal e você passa a consumir uma energia 100% limpa, mais barata, sem taxas de adesão e sem obras.</div>
                                </v-card-text>
                            </div>
                            <div class="text-center pt-12 pb-12">
                                <v-btn icon href="#how_work">
                                    <v-icon color="white" class="text-center" x-large>mdi-arrow-down-circle-outline</v-icon>
                                </v-btn>
                            </div>
                        </v-img>
                        <div id="how_work" class="pa-6 pt-12 justify-center text-left" style="background:#efefef">
                            <h3 class="pt-1">COMO FUNCIONA</h3>
                            <v-row class="pt-6 pl-3 pr-12">
                                <v-col cols="3">
                                    <h1 class="blue--text">1.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Você contrata sua energia diretamente de uma de nossas <strong>usinas solares</strong>.
                                </v-col>
                                <v-col cols="3">
                                    <h1 class="blue--text">2.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Nós <strong>comunicamos</strong> a CEMIG que agora você utiliza da <strong>energia gerada</strong> pela Juntos Energia.
                                </v-col>
                                <v-col cols="3">
                                    <h1 class="blue--text">3.</h1>
                                </v-col>
                                <v-col cols="9">
                                    <strong>Todos os meses</strong> a CEMIG avalia a energia gerada pela usina e <strong>desconta automaticamente</strong> da sua conta de luz.
                                </v-col>
                                <v-col cols="3">
                                    <h1 class="blue--text">4.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Nós emitimos uma fatura para você, referente a energia limpa consumida, <strong>com os descontos</strong> de acordo com o seu plano.
                                </v-col>
                                <!-- <v-col cols="3">
                                    <h1 class="blue--text">5.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Você continua pagando para a Cemig, mas agora o valor mínimo que corresponde a taxa de distribuição da energia.
                                </v-col> -->
                                <!-- <v-col cols="3">
                                    <h1 class="blue--text">5.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Mesmo com duas contas você passa a economizar mensalmente sem ter feito qualquer investimento, ajudando um montão o nosso planeta.
                                </v-col> -->
                                <v-col cols="3">
                                    <h1 class="blue--text">5.</h1>
                                </v-col>
                                <v-col cols="9">
                                    Viu só, você começa a <strong>economizar todos os meses</strong> sem nenhum esforço, sem nenhuma obra. Pode morar em casa, apartamento, ser proprietário ou morar de aluguel.
                                </v-col>
                            </v-row>
                            <v-row class="pt-6 pl-3 pr-6">
                                <v-btn outlined block rounded @click="dialog_howworks = false" class="mt-6 mb-6 justify-center">
                                    fechar
                                </v-btn>
                            </v-row>
                        </div>

                        <div class="ma-4 pa-3 pt-12 pb-12 pr-8 justify-center text-left">
                            <h2 class="blue--text font-weight-regular">É legal</h2>
                            <v-layout align-center>
                                <v-img src="@/assets/howworks/aneel.jpg" max-width="50%" align="center"/>
                                <v-img src="@/assets/howworks/governo.gif" max-width="50%" align="center"/>
                                <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-solar-power</v-icon> -->
                            </v-layout>
                                <h2 class="font-weight-regular pr-1">
                                    O projeto de lei 5.829 de 2019, sancionado no início de 2022 na Lei 14.300/22, institui o Marco Legal da Microgeração e Minigeração Distribuída. A lei regulamenta as modalidades de geração, o Sistema de Compensação de Energia Elétrica (SCEE) e o Programa de Energia Renovável Social (PERS)
                                </h2>
                            <!-- <v-img src="@/assets/howworks/money.png"/> -->
                        </div>
                        <div class="pa-8 pt-12 pb-12 pr-8 justify-center text-left  white--text" style="background:#00a4ff">
                            <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-nature-people</v-icon> -->
                            <h2 class="yellow--text font-weight-regular pt-12 justify-center">Por um mundo mais sustentável</h2>
                            <h2 class="font-weight-regular pr-12 pb-12 justify-center" >
                                Você entra para uma comunidade que economiza e ajuda o planeta, garantindo um futuro sustentável para nós e nossas familias.
                            </h2>
                        </div>
                        <v-img src="@/assets/howworks/solar.png" class="text-center justify-center"/>
                        <div class="ma-4 pa-3 pt-0 pb-0 pr-8 justify-center text-left  black--text">
                            <!-- <v-icon x-large class="justify-center pt-2" color="blue darken-4">mdi-nature-people</v-icon> -->
                            <h2 class="blue--text darken-2 font-weight-regular pt-12 justify-center">Pelo direito de escolha e economia</h2>
                            <h2 class="font-weight-regular pr-12 pb-2">
                                Agora você pode <strong>economizar na conta de luz</strong> da sua casa ou empresa sem ter de pagar nada a mais por isso, sem obras e instalações caras, é 100% digital, é simples e 100% confiavel.<br><strong>Já são mais de 90.000 mineiros que aderiram um novo modo de comprar energia, agora é a sua vez.</strong>
                            </h2>
                        </div>
                        <div class="pa-6 pt-4 justify-center text-left">
                            <v-btn outlined block rounded @click="dialog_howworks = false" class="mt-6 mb-6 justify-center">
                                fechar
                            </v-btn>
                        </div>
                        
                    </v-card>
                </v-row>
            </v-container>
        <!-- </v-layout> -->
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import { eventBus } from "../../main"

export default {
    mounted () {
        eventBus.$on('open-dialog_howworks', async () => {
            this.dialog_howworks = true
        })
    },
    computed: {
        ...mapState(['lead']),
        average_consumption() {
            if(this.lead) {
                return Number(this.lead.average_consumption)
            } else return 300
        },
        economy_year() {
            if(this.lead) {
                return (this.lead.average_consumption * (1 - 0.09) - 50) * (this.discount || 0.15) * 12
            } else return (300 * (1 - 0.09) - 50) * (this.discount || 0.15) * 12
        },
        economy_month() {
            if(this.lead) {
                return (this.lead.average_consumption * (1 - 0.09) - 50) * (this.discount || 0.15)
            } else return (300 * (1 - 0.09) - 50) * (this.discount || 0.15)
        }
    },
    data: () => ({
        dialog_howworks: false
    })
}
</script>