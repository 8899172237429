<template>
    <v-card 
        class="mx-auto mt-2"
        outlined
        rounded
    >
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                    {{qualification.consumer_unit}}
                </v-list-item-title>
                <p class="secondary--text mb-1">{{disqualification.text}}</p>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="40"
            >
                <v-icon x-large color="red lighten-1">mdi-alert-circle</v-icon>
            </v-list-item-avatar>
        </v-list-item>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: ['qualification'],
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState(['user', 'lead']),
        disqualification() {
            if(this.qualification.status === 'disqualified') {
                if(this.qualification.reason_disqualify === 'low_consumption') {
                    return {
                        text: 'Após análise, identificamos que o consumo médio anual deste imóvel é abaixo da média necessária para que possamos distribuir energia limpa compartilhada para o imóvel.'
                    }
                } else if(this.qualification.reason_disqualify === 'not_holder') {
                    return {
                        text: 'Após análise, identificamos que os dados do cadastro não conferem com os dados do imóvel cadastrado na concessionária.'
                    }
                } else if(this.qualification.reason_disqualify === 'low_fare') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços, pois sua tarifa atual já possui benefícios diferenciados ou é rural'
                    }
                } else if(this.qualification.reason_disqualify === 'low_savings') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços para a média de consumo de energia.'
                    }
                } else if(this.qualification.reason_disqualify === 'debits') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços, pois identificamos restrições junto a concessionária.'
                    }
                }  else if (this.qualification.reason_disqualify === "injected_energy") {
                    return {
                        text: "Após análise, identificamos que você já possui energia injetada na sua conta de luz, e, por isso, não podemos distribuir energia limpa para o seu imóvel.",
                    }
                } else {
                    return {
                        text: 'Ocorreu um erro ao analisar seu imóvel!'
                    }
                }
            } else {
                return {
                        text: 'Infelizmente não é possivel entregar energia limpa para seu imóvel!'
                }
            }
        }
    },
    methods: {
        getAddress(id) {
            if(this.user && this.user.installations_found) {
                return this.user.installations_found.filter(installation => {
                    return installation.installation_number === id
                })
            } else {
                return null
            }
        },
    }
}
</script>