import Vue from 'vue'
import VueRouter from 'vue-router'
import Simulate from '../views/Simulate.vue'
import Simulator from '../views/Simulator.vue'
import Simulator2 from '../views/Simulator2.vue'
import Simulator3 from '../views/Simulator3.vue'
import ConsumerUnits from '../views/ConsumerUnits.vue'
import Home from '../views/Home.vue'
import Indicacao  from '../views/Indicacao.vue'
import Payment from '../views/Payment.vue'
import ChangePaymentMethod from "../views/ChangePaymentMethod.vue";
import Order from '../views/Order.vue'
import Sign from '../views/Sign.vue'
import Welcome from '../views/Welcome.vue'
import OtherUtility from '../views/OtherUtility'
import ErrorisMember from '../views/Errors/IsMember'
import PaymentMethodConfirm from '../views/PaymentMethodConfirm'
Vue.use(VueRouter)

const routes = [
  { path: "*", redirect: "/energialimpa" },
  {
    path: "/energialimpa",
    name: "EnergiaLimpa",
    component: Home,
  },
  {
    path: "/simular",
    name: "Simular",
    component: Simulate,
  },
  {
    path: "/simulador",
    name: "Simulador",
    component: Simulator3,
  },
  {
    path: "/simulador2",
    name: "Simulador2",
    component: Simulator2,
  },
  {
    path: "/simulador3",
    name: "Simulador3",
    component: Simulator,
  },
  {
    path: "/imoveis",
    name: "UnidadesConsumidoras",
    component: ConsumerUnits,
  },
  {
    path: "/metodos-pagamento",
    name: "MetodosPagamento",
    component: Payment,
  },
  {
    path: "/metodos-pagamento-cadastrado",
    name: "MetodosPagamentoCadastrado",
    component: PaymentMethodConfirm,
  },
  {
    path: "/validacao",
    name: "Order",
    component: Order,
  },
  {
    path: "/assinatura",
    name: "Sign",
    component: Sign,
  },
  {
    path: "/bem-vinde",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/lista-espera",
    name: "OtherUtility",
    component: OtherUtility,
  },
  {
    path: "/lp/:id",
    name: "LP",
    component: Home,
  },
  {
    path: "/membro-ativo",
    name: "isMember",
    component: ErrorisMember,
  },
  {
    path: "/indicacao",
    name: "Indicacao",
    component: Indicacao,
  },
  {
    path: "/mudar-metodo-pagamento",
    name: "changePaymentMethod",
    component: ChangePaymentMethod,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(guard => {
  
//   console.log('befoureach: ', guard)
// })
// const router = new Router({
//   mode: "history",
//   routes: paths
//     .map(path => route(path.path, path.meta, path.view, path.name))
//     // .concat([{ path: "*", redirect: "/cadastro" }]),
//     .concat([{ path: "*", redirect: new_route}]),
    
//   scrollBehavior(to, from, savedPosition) {
//     // console.log('scrollBehavior: ', savedPosition)
//     if (savedPosition) {
//       return savedPosition;
//     }
//     if (to.hash) {
//       return { selector: to.hash };
//     }
//     return { x: 0, y: 0 };
//   }
// });

export default router
