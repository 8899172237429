<template>
    <v-container fluid class="ma-0">
        <v-row>
            <!-- <v-col cols="12" xs="12" md="6" lg="9" class="d-none d-md-block" :style="'background: url('+image+') center center;'"> -->
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <div class="d-flex justify-center pt-4">
                    <div><v-img src="@/assets/novo/logo_roxo.svg" width="200px" /></div>
                </div>
                
                <benefits :discount="discount" @udpate_discount="onUpdateDiscount"/>
                <!-- <v-col align="center" :cols="!subscribe && lead ?12:12">
                    <v-btn text small outlined rounded color="white" dark @click.prevent="showHowWorks">Clique aqui e veja como funciona</v-btn>
                </v-col> -->
                <v-container>
                    <v-row class="ml-0 mr-0 pb-2">
                        <!-- <v-col cols="12" v-if="!subscribe && lead">
                            <v-btn rounded block color="yellow" class="font-weight-black elevation-0" x-large @click="setSubscribe">MIGRAR MINHA CONTA DE LUZ</v-btn>
                        </v-col> -->
                        <!-- <v-col align="center" :cols="!subscribe && lead ?12:12">
                            <v-btn text small outlined rounded color="white" dark @click.prevent="showHowWorks">Clique aqui e veja como funciona</v-btn>
                        </v-col> -->
                    </v-row>
                    <!-- <v-row>
                        <enviroment-benefits/>
                    </v-row> -->
                    <!-- <v-row class="ml-0 mr-0 pb-2">
                        <v-col cols="12" v-if="!subscribe && lead">
                            <v-btn rounded block color="#004a7d" class="white--text font-weight-black elevation-0" x-large @click="setSubscribe">MIGRAR MINHA CONTA DE LUZ</v-btn>
                        </v-col>
                    </v-row> -->
                </v-container>
                <!-- </v-container> -->
                <steps :step="1" :total="5" :itens="steps_item" v-if="subscribe" class="mt-3"/>
                <v-container v-show="subscribe" style="background:#FFF">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                        class="ml-2 mr-2 mt-6"
                    >
                    <v-container fluid pt-0>
                        <span class="text-h7 font-weight-bold black--text text-center pb-6">Digite o {{lead.person_type === 'pf' ? "CPF" : "CNPJ"}} do titular da conta</span>
                        <v-row class="pt-4">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                                >
                                <v-combobox
                                    :value="plan"
                                    :items="plans"
                                    item-text="name"
                                    label="Planos"
                                    outlined
                                    @change="updatePlan($event)"
                                    rounded
                                    dense
                                    v-if="plans.length > 1"
                                ></v-combobox>
                                <v-text-field
                                    id="cpf"
                                    v-model="cpf"
                                    autofocus
                                    @change="clearValid($event)"
                                    :label="lead.person_type === 'pf' ? 'CPF' : 'CNPJ'"
                                    v-mask="lead.person_type === 'pf' ? '###.###.###-##' : '##.###.###/####-##'"
                                    :rules="lead.person_type === 'pf' ? cpfRules : cnpjRules"
                                ></v-text-field>
                                <!-- <p class="caption">Utilizaremos o {{lead.person_type === 'pj'?'CNPJ':'CPF'}} para identificar suas unidades na concessionária</p> -->
                                <p class="caption font-italic pt-4 info--text">Conforme a LGPD, utilizaremos o seu {{lead.person_type === 'pj'?'CNPJ':'CPF'}} apenas para a verificação de compatibilidade e eventual portabilidade</p>
                                <v-text-field 
                                    v-if="lead.person_type === 'pj'"
                                    label="Razão Social"
                                    v-model="company_name" 
                                    type="text"
                                    required
                                    :rules='[
                                        v => !!v || "Por favor, informe a razão social da empresa"
                                    ]'
                                ></v-text-field>
                                <v-text-field 
                                    v-if="lead.person_type === 'pj'"
                                    :label="'Nome Completo do Representante Legal'"
                                    v-model="name" 
                                    type="text"
                                    required
                                    :rules='[
                                        v => !!v || "Por favor, informe o nome do Representante Legal da Empresa",
                                        v => v.length >= 10 || "Por favor, preencha o nome completo."
                                    ]'
                                ></v-text-field>    
                                <v-text-field
                                    v-if="lead.person_type === 'pj'"
                                    v-model="cpf_legal_responsible"
                                    label="CPF do Representante Legal"
                                    v-mask="'###.###.###-##'"
                                    :rules="cpf_legal_responsibleRules"                                        
                                    required
                                ></v-text-field>                            
                            </v-col>
                        </v-row>
                        <v-row class="text--center justify-center pt-2 mt-0" v-if="lead && !cupom">
                            <v-col cols="12">
                                <coupon-form v-if="lead"/>
                            </v-col>
                        </v-row>
                        <v-row class="text--center justify-center pt-2 pb-0 mb-0 mt-0" v-else-if="(lead && lead.coupon && lead.coupon.coupon) || (cupom)">
                            <v-col cols="12" class="pb-0 mb-0 text-center">
                                <span class="subtitle-1">Você ativou o cupom <span class="text-uppercase font-weight-bold">{{(lead && lead.coupon && lead.coupon.coupon) || cupom.coupon}}</span></span>
                                <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" @click.stop="delCoupon"><v-icon dark color="black">mdi-trash-can-outline</v-icon></v-btn>
                                </template>
                                <span>Remover Cupom</span>
                                </v-tooltip>
                                
                            </v-col>
                            <v-col cols="10" class="text--center justify-center pa-2">

                                <div 
                                    v-if="lead && lead.coupon && lead.coupon.custom_text" v-html="lead.coupon.custom_text"
                                    class="body-2 pa-3 ma-0 black--text" 
                                    style="background: #b2f7bf; border-radius: 30px"
                                />
                                <div 
                                    v-else-if="cupom && cupom.type === 'percentage_discount'"
                                    class="body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center"
                                    style="background: #b2f7bf; border-radius: 30px"
                                >
                                 e ganhou <span class="font-weight-bold">{{ cupom.percentage_discount }}%</span> de economia todos os meses
                                </div>
                                <div
                                    v-else-if="cupom && cupom.type === 'referral'"
                                    class="body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center"
                                    style="background: #b2f7bf; border-radius: 30px"
                                >
                                você foi indicado por <span class="font-weight-bold">{{ cupom.user }}</span><br>
                                </div>
                                <div
                                    v-else-if="cupom && cupom.type === 'plan_associated'"
                                    class="body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center"
                                    style="background: #b2f7bf; border-radius: 30px"
                                >
                                e ganhou <span class="font-weight-bold">{{ cupom.percentage_discount }}%</span> de economia todos os meses
                                </div>
                                <div
                                    v-else
                                    class="body-2 pa-1 pl-2 pr-2 ma-0 black--text text-center"
                                    style="background: #b2f7bf; border-radius: 30px"
                                >
                                    você ganhou mais R$ <span class="font-weight-bold">{{ cupom.discount | formatCurrency }}</span><br> de economia na primeira fatura
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0 mt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0 mt-0"
                            >
                                <v-checkbox v-if="lead.person_type === 'pf' "
                                    v-model="titular"
                                    label="Declaro que sou titular da conta de Luz"
                                    type="checkbox"
                                    :rules="titularRules"
                                    required
                                    class="pt-0 mt-0"
                                ></v-checkbox>
                                <v-checkbox v-else
                                    v-model="titular"
                                    label="Declaro que sou o Representante Legal da Empresa"
                                    type="checkbox"
                                    :rules="titularLegalResponsibleRules"
                                    required
                                    class="pt-0 mt-0"
                                ></v-checkbox>
                                <!-- <span class="caption" v-if="lead.person_type === 'pf'" >Esse serviço pode ser contratato apenas pelo titular da conta de energia.</span> -->
                                <span class="caption" v-if="lead.person_type === 'pj'" >Esse serviço pode ser contratato apenas pelo Representante Legal da Empresa.</span>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                            >
                                <v-btn 
                                    large 
                                    rounded 
                                    block 
                                    color="primary" 
                                    :disabled="!valid || loading || error" 
                                    :loading="loading"
                                    class="font-weight-bold"
                                    @click="forceSave(lead, isMember.customer_id)"
                                >
                                    CONTINUAR
                                    <template v-slot:loader>
                                        <span>{{loading_btn}}</span>
                                        <span class="custom-loader">
                                            <v-icon light>mdi-cached</v-icon>
                                        </span>
                                    </template>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                                v-if="error && error !== ''"
                            >
                                <div class="caption red--text text-center">{{error}}</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-btn dark class="mt-3" href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank" outlined rounded block color="blue" v-if="(lead && lead.average_consumption > 1000) || this.error" :disabled="loading"><v-icon>mdi-whatsapp</v-icon>Falar com um consultor</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                </v-container>
                <div tabindex="-1" ref="focusableDiv"></div>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
        
        <!-- <v-bottom-sheet
            v-model="dialog"
            persistent
        >
            <v-card>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="dialog = false"
                        >
                            <v-icon color="grey">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <validation @startContract="startContract"/>
            </v-card>
        </v-bottom-sheet> -->
        <v-bottom-sheet
            v-model="dialog_isMember"
            persistent
        >
            <v-card v-if="isMember && isMember.code">
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="dialog_isMember = false"
                        >
                            <v-icon color="grey">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <div class="pa-6" v-html="isMember.message"/>
                    <v-row class="pa-6">
                        <v-col cols="12" align="center">
                            <v-btn large v-if="isMember.code === 'canDecided'" rounded color="#27408B" :loading="loading" dark @click="forceSave(lead, isMember.customer_id)" block>Continuar Assinatura</v-btn>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn small color="green" rounded outlined href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank">Falar com um consultor</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-bottom-sheet>
    </v-container>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import Benefits from '../components/Simulador/Benefits3.vue'
// import Validation from '../components/Validation'
import Steps from '../components/Steps'
import { eventBus } from '../main'
import { getUserByCPF, loginAnonymous } from '../services/users'
import { getActiveConsumerUnitByCPF } from '../services/consumerUnits'
import { getDadosComplementares } from '../services/getPeople'
import { convertRDStation } from '../services/rdstation'
import { formatBirthday } from '../utils/dates'
import cpf from '../utils/cpf'
import cnpj from '../utils/cnpj'
import CouponForm from '../components/Simulador/CouponForm.vue'
export default {
    components: {
        Benefits,
        // Validation,
        Steps,
        CouponForm
        // EnviromentBenefits
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    async mounted() {
        if(this.lead) {
            this.cpf = this.lead.cpf
        }
        if(this.lead.coupon) this.cupom = this.lead.coupon
        if (this.lead.coupon) {
            if (this.lead.coupon?.type === "plan_associated") {
                await this.getPlan(this.lead.coupon.plan_id + this.lead.person_type);
            } else {
                await this.getPlans({
                person_type: this.lead.person_type || "pf",
                plan_id: this.lead.coupon.associated_plan,
                utility: this.lead.utility,
                });
            }
        } else {
            await this.getPlans({
                person_type: this.lead.person_type || "pf",
                utility: this.lead.utility,
            });
        }
        eventBus.$on('startSubscribe', async () => {
            this.setSubscribe()
        })

        if(!this.lead) {
            this.$router.push({path: '/', query: this.$route.query})
            return null
        }
        if(this.$route.query && (this.$route.query.direct === "true" || this.$route.query.direct === true)) {
            this.setSubscribe()
        }

        if(this.user?.installation_numbers?.length > 0) {
            this.steps_item = ['Identificação', 'Selecionar Imóveis']
        } else {
            this.steps_item = ['Identificação', 'Conta de Luz']
        }
        
    },
    data: () => ({
        dialog: false,
        titular: false,
        cpf: null,
        cpf_legal_responsible: null,
        company_name: '',
        name: '',
        valid: null,
        code_validation: 'sms',
        titularRules: [
            v => !!v || 'Ops! É necessário ser o titular da conta para contratar o serviço.',
        ],
        titularLegalResponsibleRules: [
            v => !!v || 'Ops! É necessário ser o representante legal da empresa para contratar o serviço.',
        ],
        cpf_legal_responsibleRules: [
            v => !!v || 'Ops! Por favor, informe o CPF do Representante Legal da Empresa.',
            v => (v && v.length >= 14) || 'Informe o CPF correto do Representante Legal da Empresa.',
            v => (cpf(v) || 'CPF do Representante Legal da Empresa Inválido')
        ],
        cpfRules: [
            v => !!v || 'Ops! Por favor, informe o CPF.',
            v => (v && v.length >= 14) || 'Informe seu CPF correto.',
            v => (cpf(v) || 'CPF Inválido')
        ],
        cnpjRules: [
            v => !!v || 'Ops! Por favor, informe o CNPJ.',
            v => (v && v.length >= 14) || 'Informe o CNPJ Completo',
            v => cnpj(v) === true || 'CNPJ inválido',                                            
        ],
        subscribe: false,
        loading: false,
        error: null,
        dialog_isMember: false,
        isMember: {
            code: null,
            message: null,
            customer_id: null
        },
        loading_btn: 'Validando dados...',
        image: null,
        cupom: null,
        page_direct: false,
        steps_item: ['Identificação', 'Conta de Luz']
    }),
    computed: {
        ...mapState(['lead', 'user', 'coupon', 'plans', 'plan', 'referral_code']),
        discount() {
            if(this.lead && this.lead.coupon && this.lead.coupon.percentage_discount)
                return (this.lead.coupon.percentage_discount / 100)
            else if(this.lead && this.lead.person_type === 'pj')
                return 0.2
            else {
                return this.plan ? (this.plan.discount / 100) : 0.15
            }
        }
    }, 
    watch: {
        coupon(val) {
            this.cupom = val
            if(val && val.percentage_discount) this.discount_value = (val.percentage_discount / 100)
        }
    },
    methods: {
      ...mapActions(['saveLead', 'saveUser',  'facebookSignUp', 'googleSignUp', 'login', 'getLocalUser', 'getLocalLead', 'getPlan', 'getPlans', 'getCoupons', 'deleteCoupon']),
      updatePlan(plan) {
          this.$store.commit('setPlan', plan)
      },
      async startContract () {
        this.error = null
        this.loading = true
        this.dialog = false
        if(this.valid) {
            let lead = {
                ...this.lead,
                cpf: this.cpf.replace(/\D/g, ''),
                titular: this.titular,
            }
            if(this.coupon) {
                lead.coupon = this.coupon
            }
            if(this.referral_code) lead.promo_code = this.referral_code
            getUserByCPF(lead.cpf)
                .then(res => {
                    if(res.size > 0) {
                        if(res.size > 0 && res.docs[0].data() && res.docs[0].data().status === 'ATIVO') {
                            this.isMember = {customer_id: res.docs[0].id, code: 'isMemberActivated', message: 'Verificamos que você já é um membro ativo, com o e-mail <span class="font-weight-bold">' + res.docs[0].data().email + '</span>. Se deseja adicionar mais um imóvel clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                            this.loading = false
                            this.dialog_isMember = true
                        } else if (res.size > 0 && res.docs[0].data() && res.docs[0].data().status === 'onboarding') {
                            lead.customer_id = res.docs[0].id
                            this.save(lead, true)
                        }
                        else if(res.size > 0) {
                            this.isMember = {customer_id: res.docs[0].id, code: 'canDecided', message: 'Verificamos que você já possui um cadastro com o e-mail <span class="font-weight-bold">' + res.docs[0].data().email + '</span>. <p class="font-weight-bold">O que deseja fazer?</p>'}
                            this.loading = false
                            this.dialog_isMember = true
                        } else {
                            {
                                this.isMember = {customer_id: null, code: 'isMemberActivated', message: 'Verificamos que você já é um membro ativo, com esse e-mail. Clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                                this.loading = false
                                this.dialog_isMember = true
                            }
                        }
                    } else this.save(lead, false)
                })
                .catch(err => console.error(err))
        }
      },
      onUpdateDiscount(val) {
          this.discount = val
      },
      clearValid(cpf) {
        this.valid = null
        this.error = null
        getActiveConsumerUnitByCPF(cpf.replace(/\D/g, ''))
            .then(ucs => {
                if(ucs.size > 0) {
                    let msg = 'Verificamos que você já possui unidade(s) consumidora(s) ativa(s). Se deseja adicionar mais um imóvel clique no botão abaixo que um de nossos consultores irá ajudá-lo.'
                    this.isMember = {customer_id: ucs.docs[0].id, code: 'isMemberActivated', message: msg}
                    this.loading = false
                    this.dialog_isMember = true
                    this.valid = false
                    this.error = msg                }
            })
            .catch(err => {
                console.error('Error: ', err)
            })
      },
      submit() {
          if (this.$refs.form.validate()) {
            this.error = null
            this.loading = true
            if(this.user && !this.user.providerID ) {
                this.loading = false
                this.dialog = true
            }
            else if(!this.user) {
                this.loading = false
                this.dialog = true
            }
            else {
                this.loading = false
                this.startContract()
            }
          }
      },
      setSubscribe() {
            this.$gtm.trackEvent({
                event: 'step2_cpf',
                category: "onboard",
                action: "pageview",
                label: "Acessou a pagina para informar o CPF",
                value: this.lead.average_consumption,
                noninteraction: false, // Optional
                step: this.$route.name,
                email: this.lead.email,
                person_type: this.lead.person_type,
                phone: this.lead.phone,
            });

            
        convertRDStation('step2_cpf', this.lead)
            .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
            .then(() => console.log('Conversão step2_cpf gravada com sucesso no RD Station'))

            this.subscribe = true
            this.$vuetify.goTo(this.$refs.focusableDiv)
      },
      showHowWorks() {
        eventBus.$emit('open-dialog_howworks')
      },
      async save(_lead, force) {
        // Remove o dialog quando a gravação é solicitada mesmo que já exista um cadastro
        if(force) this.dialog_isMember = false

        // Seta o CPF e titular informados nessa página caso não estejam no LEAD
        if(_lead.person_type === "pf") {
        _lead.cpf = this.cpf.replace(/\D/g, '')
        _lead.contract_time = this.plan.contract_time
        _lead.titular = this.titular
        _lead.plan = {
            id: this.plan.id,
            contract_time: this.plan.contract_time,
            discount: this.plan.discount,
            name: this.plan.name,
            person_type: this.plan.person_type || ""
            }
        }
        else {
        _lead.cpf = this.cpf.replace(/\D/g, '')
        _lead.contract_time = this.plan.contract_time
        _lead.titular = this.titular
        _lead.company_name = this.company_name
        _lead.name = this.name
        _lead.cpf_legal_responsible = this.cpf_legal_responsible.replace(/\D/g, '')
        _lead.plan = {
            id: this.plan.id,
            contract_time: this.plan.contract_time,
            discount: this.plan.discount,
            name: this.plan.name,
            person_type: this.plan.person_type || ""
            }
        }

        if(!localStorage.getItem('token')) {
            await loginAnonymous()
                .then((res) => {
                    let token = res.user.za
                    if(token) {
                        localStorage.setItem('token', token)
                        this.$store.commit("setToken", token)
                    }
                })
                .catch(err => console.error(err))
        }

        await getActiveConsumerUnitByCPF(_lead.cpf)
            .then(ucs => {
                if(ucs.size > 0) {
                    let msg = 'Verificamos que você já possui unidade(s) consumidora(s) ativa(s). Se deseja adicionar mais um imóvel clique no botão abaixo que um de nossos consultores irá ajudá-lo.'
                    this.isMember = {customer_id: ucs.docs[0].id, code: 'isMemberActivated', message: msg}
                    this.loading = false
                    this.dialog_isMember = true
                    this.valid = false
                    this.error = msg
                }
            })
            .catch(err => {
                console.error('Erro ao validar se usuário existe: ', err)
            })

        if((!_lead.date_of_birth || (!_lead.gender && !_lead.ID_document) || _lead.date_of_birth === '' || _lead.gender === '') && _lead.person_type === 'pf') {
          await getDadosComplementares(_lead.cpf)
              .then(result => {
                if(result.data.BasicData) {
                  _lead.nome_titular = result.data.BasicData.Name || '',
                  _lead.gender = result.data.BasicData.Gender || ''
                  _lead.nacionalidade = result.data.BasicData.BirthCountry || 'Brasileiro(a)'
                  if(result.data.BasicData.AlternativeIdNumbers && result.data.BasicData.AlternativeIdNumbers.RG)
                    _lead.ID_document = result.data.BasicData.AlternativeIdNumbers
                  _lead.mother_name = result.data.BasicData.MotherName || ''
                  _lead.date_of_birth = formatBirthday(result.data.BasicData.BirthDate) || ''
                }
              })
              .catch(async error => {
                console.error(error)
              })
        }

        _lead.step = this.$route.name
        _lead.send_simulate = true
        // Salva o Lead no banco de dados e no estado da aplicação
        if(this.referral_code) _lead.promo_code = this.referral_code

        // Caso o cupom seja referral, adiciona indicação para o membro
        if (_lead && _lead.coupon && _lead.coupon.user && _lead.coupon.type === 'referral') _lead.promo_code = _lead.coupon.user

        _lead.origin = "onboarding"

        await this.saveLead(_lead)
            .then(async () => {
                await this.saveUser(_lead)
                .then(() => {
                    this.$router.push({path: '/imoveis', query: this.$route.query})
                })
                .catch(err => console.error(err))
            })
            .catch(error => {
                console.log(error)
            })
      },
      async forceSave(lead, id) {
          this.loading = true
          if(id) lead.customer_id = id
          this.save(lead, true)
      },
      async delCoupon() {
            this.lead.coupon = null
            this.cupom = null
            this.deleteCoupon()
            await this.getPlans({
                person_type: this.lead.person_type || "pf",
                utility: this.lead.utility,
            });
      }
    }
}
</script>
<style scoped>
    .v-dialog--fullscreen {
        border-radius: 0;
        margin: 0;
        height: 70%;
        position: fixed;
        overflow-y: auto;
        top: 200px;
        left: 0;
    }
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
</style>