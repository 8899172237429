import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatarData', value => {
    if (!value) return ''
    let timestamp = value // firebase data
    let myDate = new Date()
    if(timestamp._seconds) {
      myDate = new Date(timestamp._seconds * 1000) // date object
    } else {
      myDate = new Date(timestamp) // date object
    }

    return  moment(myDate.toISOString().substr(0, 10)).format('MM/YYYY')
})
Vue.filter('formatarDate', value => {
  if (!value) return ''
  let timestamp = value // firebase data
  let myDate = new Date()
  if(timestamp._seconds) {
    myDate = new Date(timestamp._seconds * 1000) // date object
  } else if(timestamp.seconds) {
    myDate = new Date(timestamp.seconds * 1000) // date object
  } else {
    myDate = new Date(timestamp) // date object
  }
  return  moment(myDate.toISOString().substr(0, 10)).format('DD/MM/YYYY')
})
Vue.filter('formatStatus', value => {
    if(!value) return ''
    
    if(value === 'paga' || value === 'succeeded' || value === 'paid') {
      return 'done_outline'
    }
    else if(value === 'aberta' || value === 'pending') {
      return 'schedule'
    }
    else if(value === 'overdue' || value === 'refused') {
      return 'schedule'
    }
    else if(value === 'refused') {
      return 'credit_card_off'
    }
    else if(value === 'free') {
      return 'card_giftcard'
    }
    else if(value === 'failed') {
      return 'highlight_off'
    }
    else if(value === 'processing') {
      return 'hourglass_bottom'
    }
    else if(value === 'generation') {
      return 'hourglass_top'
    } else {
      return 'help_outline'
    }
})

Vue.filter('formatUserStatus', value => {
  if(!value) return ''
  value = value.toUpperCase()
  if(value === 'ATIVO') {
    return 'ATIVO'
  }
  else if(value === 'INATIVO' || value === 'CANCELED') {
    return 'CANCELADO'
  }
  else if(value === 'SENDED_PROPOSAL') {
    return 'PROPOSTA ENVIADA'
  }
  else if(value === 'REQUEST_PROPOSAL') {
    return 'PROPOSTA SOLICITADA'
  }
  else if(value === 'NOT_HOLDER') {
    return 'NÃO É O TITULAR'
  }
  else if(value === 'AGUARDANDO_CONTA') {
    return 'AGUARDANDO CONTA'
  }
  else if(value === 'MULTIPLAS_CONTAS') {
    return 'MULTIPLAS INSTALAÇÕES'
  }
  else if(value === 'SEM_RETORNO') {
    return 'SEM RETORNO'
  }
  else if(value === 'QUALIFICAR_MANUALMENTE') {
    return 'QUALIFICAR MANUALMENTE'
  }
  else if(value === 'PAYMENT_METHOD_NOT_SELECTED') {
    return 'SEM FORMA DE PAGTO'
  }
  else if(value === 'IN_QUEUE') {
    return 'NA FILA'
  }
  else if(value === 'CANCELING') {
    return 'EM CANCELAMENTO'
  }
  else if(value === 'WAIT_ADD_CREDIT_CARD') {
    return 'AGUARDANDO INCLUSAO DE CARTAO'
  }
  else if(value === 'AGUARDANDO_NRO_INSTALACAO') {
    return 'AGUARDANDO NRO INSTALAÇÃO'
  } 
  else if(value === 'VALIDAR_DOCUMENTO') {
    return 'VALIDAR DOCUMENTO'
  }  
  else if(value === 'AGUARDANDO_DOCUMENTO') {
    return 'AGUARDANDO DOCUMENTO'
  } else {
    return value
  }
})

Vue.filter('formatUserStatusColor', value => {
  if(!value) return ''
  value = value.toUpperCase()
  if(value === 'ATIVO') {
    return 'green'
  }
  else if(value === 'INATIVO' || value === 'CANCELED') {
    return 'red'
  }
  else if(value === 'SENDED_PROPOSAL') {
    return 'grey'
  }
  else if(value === 'REQUEST_PROPOSAL') {
    return 'grey'
  }
  else if(value === 'NOT_HOLDER') {
    return 'grey'
  }
  else if(value === 'AGUARDANDO_CONTA') {
    return 'grey'
  }
  else if(value === 'MULTIPLAS_CONTAS') {
    return 'grey'
  }
  else if(value === 'SEM_RETORNO') {
    return 'black'
  }
  else if(value === 'QUALIFICAR_MANUALMENTE') {
    return 'orange'
  }
  else if(value === 'PAYMENT_METHOD_NOT_SELECTED') {
    return 'orange'
  }
  else if(value === 'IN_QUEUE') {
    return 'orange'
  }
  else if(value === 'CANCELING') {
    return 'red'
  }
  else if(value === 'WAIT_ADD_CREDIT_CARD') {
    return 'orange'
  }
  else if(value === 'AGUARDANDO_NRO_INSTALACAO') {
    return 'grey'
  } else {
    return 'success'
  }
})

Vue.filter('formatUserStatusDesc', value => {
  if(!value) return ''
  value = value.toUpperCase()
  if(value === 'ATIVO') {
    return 'O membro passou pelo processo de qualificação e já está apto a receber energia limpa'
  }
  else if(value === 'INATIVO' || value === 'CANCELED') {
    return 'O membro está cancelado em nosso sistema.'
  }
  else if(value === 'SENDED_PROPOSAL') {
    return 'Proposta foi enviada ao membro'
  }
  else if(value === 'REQUEST_PROPOSAL') {
    return 'PROPOSTA SOLICITADA'
  }
  else if(value === 'NOT_HOLDER') {
    return 'A inscrição foi realizada por um CPF que não foi identificado na concessionária'
  }
  else if(value === 'AGUARDANDO_CONTA') {
    return 'A inscrição foi realizada, porém, ainda não foi encontrada conta na Cemig. '+
    '\nFoi solicado ao inscrito que envie uma conta de luz.\n' +
    '\nSerão enviados 3 mensagens consecutivas e depois uma por semana.\n' +
    '\nO sistema tentará realizar a qualificação automatica 3 dias seguidos e depois uma vez por semana.\n'
  }
  else if(value === 'MULTIPLAS_CONTAS') {
    return 'MULTIPLAS INSTALAÇÕES'
  }
  else if(value === 'SEM_RETORNO') {
    return 'SEM RETORNO'
  }
  else if(value === 'QUALIFICAR_MANUALMENTE') {
    return 'Ocorreu algum erro durante o processo de qualificação, por favor, abrir ocorrência ao time de qualificação'
  }
  else if(value === 'QUALIFICAR') {
    return 'Inscrição aguardando qualificação, o processo roda normalmente de 30 em 30 minutos, caso ultrapasse 4 horas abrir ocorrência ao time de qualificação.'
  }
  else if(value === 'PAYMENT_METHOD_NOT_SELECTED') {
    return 'SEM FORMA DE PAGTO'
  }
  else if(value === 'IN_QUEUE') {
    return 'NA FILA'
  }
  else if(value === 'CANCELING') {
    return 'Membro solicitou o cancelamento e está aguardando ser substituído na concessionária. O prazo maximo para isso é 90 dias da data de solicitação.'
  }
  else if(value === 'WAIT_ADD_CREDIT_CARD') {
    return 'AGUARDANDO INCLUSAO DE CARTAO'
  }
  else if(value === 'AGUARDANDO_NRO_INSTALACAO') {
    return 'A inscrição foi realizada por um CPF que não foi identificado na concessionária, aguardando informação sobre o numero de instalação.'
  }
  else if(value === 'VALIDAR_DOCUMENTO') {
    return 'VALIDAR DOCUMENTO'
  }  
  else if(value === 'AGUARDANDO_DOCUMENTO') {
    return 'AGUARDANDO DOCUMENTO'
  } else {
    return value
  }
})

Vue.filter('formatStatusBillingColor', value => {
  if(!value) return ''
  
  if(value === 'paga' || value === 'succeeded' || value === 'paid') {
    return 'green'
  }
  else if(value === 'aberta' || value === 'pending') {
    return 'grey'
  }
  else if(value === 'overdue' || value === 'refused') {
    return 'red'
  }
  else if(value === 'free') {
    return 'blue'
  }
  else if(value === 'failed') {
    return 'red'
  }
  else if(value === 'processing') {
    return 'orange'
  }
  else if(value === 'generation') {
    return 'orange'
  }
  else if(value === 'canceled') {
    return 'black'
  } else {
    return 'teal'
  }
  
})

Vue.filter('formatCurrency', value => {
    let val = (value*1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('formatCurrencyInteger', value => {
  let val = (value*1).toFixed(0)
  return val
})

Vue.filter('formatCurrencyDecimal', value => {
  let val = (value*1).toFixed(2).split('.')[1]
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('formatCompetence', value => {
  let year = value.toString().substr(0,4)
  let month = value.toString().substr(4,2)
  return month + year
})

Vue.filter('formatCompetenceTraco', value => {
  let year = value.toString().substr(0,4)
  let month = value.toString().substr(5,2)
  return month + '/' + year
})

Vue.filter('formatTransactionStatus', value => {
  if(!value) return ''
  else if(value === 'processing') return 'PROCESSANDO'
  else if(value === 'authorized') return 'AUTORIZADA'
  else if(value === 'paid') return 'PAGO'
  else if(value === 'refunded') return 'ESTORNADA'
  else if(value === 'waiting_payment' || value === 'opened' || value === 'pending') return 'AGUARDANDO PAGAMENTO'
  else if(value === 'pending_refund') return 'AGUARDANDO ESTORNO'
  else if(value === 'refused') return 'CARTÃO RECUSADO'
  else if(value === 'chargedback') return 'CHARGEBACK'
  else if(value === 'analyzing') return 'EM ANALISE'
  else if(value === 'pending_review') return 'REVISÃO MANUAL'
  else if(value === 'free') return 'ABONADO'
  else if(value === 'overdue') return 'ATRASADA'
  else if(value === 'reprocessing') return 'REPROCESSAR'
  else if(value === 'generating') return 'GERANDO FATURA'
  else if(value === 'canceled') return 'ATRASADA'
  else return value
})

Vue.filter('formatInt', value => {
  if(!value) return 0
  return Number(value).toFixed(0)
})

Vue.filter('formatUCStatus', value => {
  if(!value) return ''
  
  if(value.toLowerCase() === 'activated' || value==='ATIVO') {
    return 'Ativo'
  }
  else if(value.toLowerCase() === 'inactivated' || value.toLowerCase() === 'canceled') {
    return 'Cancelado'
  }
  else if(value.toLowerCase() === 'canceling') {
    return 'Em cancelamento'
  } else {
    return value
  }
})

Vue.filter('formatUCStatusColor', value => {
  if(!value) return ''
  
  if(value === 'activated') {
    return 'green'
  }
  else if(value === 'inactivated' || value === 'canceled') {
    return 'red'
  }
  else if(value === 'canceling') {
    return 'orange'
  }
})

Vue.filter('formatUCStatusIcon', value => {
  if(!value) return ''
  
  if(value === 'activated') {
    return 'person'
  }
  else if(value === 'inactivated' || value === 'canceled') {
    return 'person_remove'
  }
  else if(value === 'canceling') {
    return 'person_outline'
  }
})

Vue.filter('formatPaymentType', value => {
  if(!value) return ''
  
  if(value === 'boleto') {
    return 'Boleto'
  }
  else if(value === 'pix') {
    return 'Pix'
  }
  else if(value === 'credit_card' || value === 'credit_card') {
    return 'Cartão de Crédito'
  }
})

Vue.filter('formatPaymentTypeIcon', value => {
  if(!value) return ''
  
  if(value === 'boleto') {
    return 'mdi-barcode'
  }
  else if(value === 'pix') {
    return 'qr_code_2'
  }
  else if(value === 'credit_card' || value === 'credit_card') {
    return 'credit_card'
  }
})

Vue.filter('clearPhone', value => {
  if(!value) return ''
  value = value.replace(/[^\d]/g, '')
  if(value.length >= 13 && value.substring(0,2) == '55') return value.substring(2,value.length)
  return value
})

Vue.filter('firstName', value => {
  if(!value) return ''
  value = value.split(' ')[0]
  value = value[0].toUpperCase() + value.substring(1).toLowerCase();
  return value
})