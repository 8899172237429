import { render, staticRenderFns } from "./QualifieldCard.vue?vue&type=template&id=2f499840&"
import script from "./QualifieldCard.vue?vue&type=script&lang=js&"
export * from "./QualifieldCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/zanon/Documentos/Juntos/onboarding/onboarding/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f499840')) {
      api.createRecord('2f499840', component.options)
    } else {
      api.reload('2f499840', component.options)
    }
    module.hot.accept("./QualifieldCard.vue?vue&type=template&id=2f499840&", function () {
      api.rerender('2f499840', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Qualification/QualifieldCard.vue"
export default component.exports