<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg" />
      <v-col cols="12" md="6" lg="4" class="pa-0 form">
        <v-col class="text-center pb-0">
          <p class="mb-0 text-center">Olá, {{ user.name }}!</p>
        </v-col>
        <card-form />
      </v-col>
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg" />
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

import CardForm from "../components/ChangePaymentMethod/CardForm.vue";

export default {
  data: () => ({
    valid: true,
    user: null,
  }),
  components: {
    CardForm,
  },
  methods: {
    ...mapActions(["getUserInitial"]),
  },

  mounted: async function () {
    const email = this.$route.query.email ?? null;
    try {
      if (!email) {
        throw new Error("Nenhum email enviado na query");
      }

      this.user = await this.getUserInitial(email);
      if (this.user) {
        if (!this.user.signedcontract_at && this.user.status !== "ATIVO") {
          throw new Error("Usuário não ativo ou não possui contrato assinado.");
        }
      } else {
        throw new Error("Usuário não encontrado");
      }
    } catch (error) {
      this.$router.push({ path: "/energialimpa" }).catch((err) => {
        return err;
      });
      throw new Error(error);
    }
  },
};
</script>
