<template>
  <v-container class="ma-0" fluid wrap fill-height>
    <v-row style="height: 100vh">
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg" />
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-container class="pa-6">
          <v-img src="@/assets/novo/logo_roxo.svg" width="230px" />
          <h1 class="pt-12">Pode falar pra todo mundo, {{ user ? user.name : '' | firstName }}</h1>
          <h3>
            <p>Muito bom ter você na comunidade da Juntos Energia</p>
            <v-row class="justify-center text-center">
              <v-img src="@/assets/benefits/034-hydro-power.svg" max-width="230px" />
            </v-row>
            <p class="body-1 font-weight-bold pt-3">
              A sua portabilidade foi concluída com sucesso!
              Você vai passar a consumir energia limpa no seu imóvel, pois a sua inscrição na Juntos foi concluída com
              sucesso.
            </p>
            <p class="body-1 font-weight-normal pt-3">
              Em breve você receberá um e-mail com os detalhes da sua adesão e próximos passos.
            </p>
          </h3>
          <v-divider class="pt-1 pb-1" />
        </v-container>
        <v-row class="d-xl-flex pl-6 pr-6">
          <v-col cols="12">
            Enquanto isso, baixe o app para indicar amigos e receber ainda mais desconto na sua conta
          </v-col>
          <v-col cols="6">
            <a href="https://apps.apple.com/br/app/enercred/id1465641135" target="_blank"><v-img
                src="@/assets/app/app-store-badge.png" max-width="200" /></a>
          </v-col>
          <v-col cols="6">
            <a href="https://play.google.com/store/apps/details?id=com.enecred.enercredapp&hl=pt_BR&gl=US"
              target="_blank"><v-img src="@/assets/app/play-store-badge.png" max-width="200" /></a>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg" />
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { convertRDStation } from "../services/rdstation";

export default {
  created() {
    if (!this.user) {
      this.getLocalUser();
    }

    if (!this.lead) {
      this.getLocalLead();
    }
    if (this.user && this.lead && !this.qualifications) {
      this.loading = true;
      this.getQualification(this.user.cpf)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'step7_tela_sucesso',
      category: "onboard",
      action: "pageview",
      label: "Acessou pagina de bem vindo a Juntos",
      noninteraction: false, // Optional
      step: this.$route.name,
      email: this.user?.email || this.lead?.email,
      phone: this.user?.phone || this.lead?.phone,
      person_type: this.user?.person_type || this.lead?.person_type,
      value: this.user?.average_consumption || this.lead?.average_consumption,
    });
    convertRDStation('step7_tela_sucesso', this.lead)
      .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
      .then(() => console.log('Conversão step7_tela_sucesso gravada com sucesso no RD Station'))
  },
  data() {
    return {
      loading: false,
      image: null,
      timeline: [
        {
          step: "Validação e Alocação",
          color: "green",
          description:
            "Nosso time irá fazer as validações finais e alocar você em uma de nossas usinas",
        },
        {
          step: "Aprovação concessionária",
          color: "green",
          description:
            "Enviamos seus dados para a concessionária e ela faz o cadastro do seu imóvel para receber energia de nossas usinas",
        },
        {
          step: "Recebimento e Faturamento",
          color: "green",
          description:
            "Assim que você receber energia de nossa usinas nosso time gera uma fatura com os descontos combinados.",
        },
        {
          step: "Economia e Sustentabilidade",
          color: "green",
          description:
            "Você economiza todos os meses e conta aos seus amigos e familiares como está ajudando o planeta.",
        },
      ],
    };
  },
  computed: {
    ...mapState(["lead", "user", "qualifications"]),
  },
  methods: {
    ...mapActions(["getQualification", "getLocalUser", "getLocalLead"]),
  },
  filters: {
    firstName: function (v) {
      if (!v) return "";
      else {
        let firstname = v.split(" ")[0];
        return firstname[0].toUpperCase() + firstname.substr(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app {
  background: #efefef;
  color: darkblue;
  height: 100vh;
}
</style>