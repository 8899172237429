<template>
    <v-container fluid class="ma-0">
        <v-row>
            <v-col cols="12" xs="12" md="6" lg="8" class="d-none d-md-block" :style="'background: url('+image+') center center;'">
                <!-- <v-img :src="require('@/assets/usinas/5.jpg')"/> -->
            </v-col>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <!-- <v-img src="@/assets/usinas/5.jpg" width="100%" height="200px"/> -->
                
                <!-- <v-container> -->
                <!-- <steps :step="3" :total="3" :itens="['']"/> -->
                <v-card flat class="overflow-hidden" color="#efefef">
                    <v-row class="pa-3 mt-0" align="center">
                        <v-col cols="12" align="center">
                            <v-img src="@/assets/novo/logo.png" width="50%"/>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card flat class="ml-3 mr-3 pl-3 pr-3">
                    <benefits @udpate_discount="onUpdateDiscount"/>
                </v-card>
                <v-container>
                    <v-row class="ml-0 mr-0 pb-2">
                        <v-col cols="12" v-if="!subscribe && lead && lead.person_type === 'pf'">
                            <v-btn rounded block color="blue darken-2" dark large @click="setSubscribe">ASSINAR AGORA</v-btn>
                        </v-col>
                        <v-col align="center" :cols="!subscribe && lead && lead.person_type === 'pf'?12:12">
                            <v-btn small outlined color="blue darken-1" rounded dark @click.prevent="showHowWorks">Clique aqui e veja como funciona</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="!subscribe && lead && lead.person_type === 'pj'">
                            <v-btn rounded block color="primary" href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank">Falar com um consultor</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <!-- </v-container> -->
                <steps :step="1" :total="5" :itens="['Garanta sua vaga', 'Imóveis']" v-if="subscribe" class="mt-3"/>
                <v-container v-show="subscribe">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="submit"
                        class="ml-2 mr-2 mt-6"
                    >
                    <v-container fluid pt-0>
                        <!-- <v-row>
                            <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="text-center mt-2"
                            >
                                <h2>Garanta sua vaga</h2>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                                >
                                <v-combobox
                                    :value="plan"
                                    :items="plans"
                                    item-text="name"
                                    label="Planos"
                                    outlined
                                    @change="updatePlan($event)"
                                    rounded
                                    dense
                                ></v-combobox>
                                <v-text-field
                                    v-model="cpf"
                                    ref="cpf"
                                    label="CPF do titular"
                                    required
                                    v-mask="'###.###.###-##'"
                                    :rules="cpfRules"
                                    filled
                                    rounded
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0 mt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0 mt-0"
                            >
                                <v-checkbox
                                    v-model="titular"
                                    label="Declaro que sou o titular da Conta de Energia"
                                    type="checkbox"
                                    :rules="titularRules"
                                    required
                                    class="pt-0 mt-0"
                                ></v-checkbox>
                                <span class="caption">Esse serviço pode ser contratato apenas pelo titular da conta de energia.</span>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                                v-if="error !== ''"
                            >
                                <div class="caption red--text text-center">{{error}}</div>
                            </v-col>
                        </v-row>
                        <v-row class="pt-0">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                class="pt-0"
                            >
                                <v-btn 
                                    large 
                                    rounded 
                                    block 
                                    color="primary" 
                                    :disabled="!valid || loading" 
                                    :loading="loading"
                                    v-if="!error"
                                    @click="submit">
                                        CONTINUAR
                                        <template v-slot:loader>
                                            <span>{{loading_btn}}</span>
                                            <span class="custom-loader">
                                                <v-icon light>mdi-cached</v-icon>
                                            </span>
                                        </template>
                                    </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-btn class="mt-3" href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank" outlined rounded block color="green" v-if="(lead && lead.average_consumption > 300) || this.error" :disabled="loading">Falar com um consultor</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                </v-container>
                <div tabindex="-1" ref="focusableDiv"></div>
            </v-col>
        </v-row>
        
        <v-bottom-sheet
            v-model="dialog"
            persistent
        >
            <v-card>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="dialog = false"
                        >
                            <v-icon color="grey">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <validation @startContract="startContract"/>
            </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet
            v-model="dialog_isMember"
            persistent
        >
            <v-card v-if="isMember && isMember.code">
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="dialog_isMember = false"
                        >
                            <v-icon color="grey">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <div class="pa-6" v-html="isMember.message"/>
                    <v-container>
                    <div class="pa-6" v-html="isMember.message"/>
                    <v-row class="pa-6">
                        <v-col cols="12" align="center">
                            <v-btn large v-if="isMember.code === 'canDecided'" rounded color="#27408B" :loading="loading" dark @click="forceSave(lead, isMember.customer_id)" block>Continuar Assinatura</v-btn>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn small color="green" rounded outlined href="https://api.whatsapp.com/send/?phone=5535998625580&text=Ol%C3%A1!" target="_blank">Falar com um consultor</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                </v-container>
            </v-card>
        </v-bottom-sheet>
    </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Benefits from '../components/Simulador/Benefits2.vue'
import Validation from '../components/Validation'
import Steps from '../components/Steps'
import { eventBus } from '../main'
import { getUserByCPF, loginAnonymous } from '../services/users'
import { getDadosComplementares } from '../services/getPeople'
import { formatBirthday } from '../utils/dates'
import cpf from '../utils/cpf'
import images from '../utils/images'
import md5 from "md5"
export default {
    components: {
        Benefits,
        Validation,
        Steps
    },
    async mounted() {
        
        if(this.lead) {
            this.cpf = this.lead.cpf
        }
        if(!this.plans || this.plans.length <= 0) {
            await this.getPlans({person_type: this.lead.person_type, utility: this.lead.utility})
        }
        this.image = images.images[Math.floor((Math.random() * images.images.length))]
    },
    data: () => ({
        dialog: false,
        titular: false,
        cpf: null,
        valid: null,
        code_validation: 'sms',
        cpfRules: [
            v => !!v || 'Ops! Por favor, informe o CPF.',
            v => (v && v.length >= 14) || 'Informe seu CPF correto.',
            v => (cpf(v) || 'CPF Inválido')
        ],
        titularRules: [
            v => !!v || 'Ops! É necessário ser o titular da conta para contratar o serviço.',
        ],
        subscribe: false,
        loading: false,
        error: null,
        dialog_isMember: false,
        isMember: {
            code: null,
            message: null,
            customer_id: null
        },
        loading_btn: 'Validando dados...',
        image: null,
        // contract_time_items_pf: [
        //     {text: 'Sem fidelidade', value: 0, discount: 0.10},
        //     {text: '12 Meses (Recomendado)', value: 12, discount: 0.15},
        //     {text: '24 Meses', value: 24, discount: 0.18},
        //     {text: '36 Meses', value: 36, discount: 0.20},
        // ],
        // contract_time: {text: '12 Meses (Recomendado)', value: 12, discount: 0.15},
        // discount: 0.15
    }),
    computed: {
        ...mapState(['lead', 'user', 'coupon', 'plans', 'plan']),
        ...mapGetters(['plansOrder']),
        discount() {
            if(this.lead && this.lead.coupon && this.lead.coupon.percentage_discount)
                return (this.lead.coupon.percentage_discount / 100)
            else if(this.lead && this.lead.person_type === 'pj')
                return 0.2
            else {
                // console.log('this.contract_time: ', this.plan ? this.plan.discount : 'sem plano')
                return this.plan ? (this.plan.discount / 100) : 0.15
            }
        }
    },
    methods: {
      ...mapActions(['saveLead', 'saveUser',  'facebookSignUp', 'googleSignUp', 'login', 'getLocalUser', 'getLocalLead', 'getPlans']),
      updatePlan(plan) {
          this.$store.commit('setPlan', plan)
      },
      async startContract () {
        this.error = ''
        this.loading = true
        this.dialog = false
        if(this.valid) {
            let lead = {
                ...this.lead,
                cpf: this.cpf.replace(/\D/g, ''),
                titular: this.titular,
            }
            if(this.coupon) lead.coupon = this.coupon
            getUserByCPF(lead.cpf)
                .then(res => {
                    if(res.size > 0) {
                        if(res.docs.length > 0 && res.docs[0].data() && res.docs[0].data().status === 'ATIVO') {
                            this.isMember = {customer_id: res.docs[0].id, code: 'isMemberActivated', message: 'Verificamos que você já é um membro ativo, com o e-mail <span class="font-weight-bold">' + res.docs[0].data().email + '</span>. Se deseja adicionar mais um imóvel clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                            this.loading = false
                            this.dialog_isMember = true
                        } else if (res.docs.length > 0 && res.docs[0].data() && res.docs[0].data().status === 'onboarding') {
                            lead.customer_id = res.docs[0].id
                            this.save(lead, true)
                        }
                        else if(res.docs.length > 0) {
                            this.isMember = {customer_id: res.docs[0].id, code: 'canDecided', message: 'Verificamos que você já possui um cadastro com o e-mail <span class="font-weight-bold">' + res.docs[0].data().email + '</span>. <p class="font-weight-bold">O que deseja fazer?</p>'}
                            this.loading = false
                            this.dialog_isMember = true
                        } else {
                            {
                                this.isMember = {customer_id: null, code: 'isMemberActivated', message: 'Verificamos que você já é um membro ativo, com esse e-mail. Clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                                this.loading = false
                                this.dialog_isMember = true
                            }
                        }
                    } else this.save(lead, false)
                })
                .catch(err => console.error(err))
        }
      },
      onUpdateDiscount(val) {
          this.discount = val
      },
      submit() {
          if (this.$refs.form.validate()) {
            this.error = ''
            this.loading = true
            if(this.user && !this.user.providerID ) {
                this.loading = false
                this.dialog = true
            }
            else if(!this.user) {
                this.loading = false
                this.dialog = true
            }
            else {
                this.loading = false
                this.startContract()
            }
          }
      },
      setSubscribe() {
            // Seta o CPF e titular informados nessa página caso não estejam no LEAD
            let _lead = {
                ...this.lead,
                cpf: this.cpf.replace(/\D/g, ''),
                contract_time: this.plan.contract_time,
                titular: this.titular,
                plan: {
                    id: this.plan.id,
                    contract_time: this.plan.contract_time,
                    discount: this.plan.discount,
                    name: this.plan.name,
                    person_type: this.plan.person_type || ""
                }
            }
            this.saveLead(_lead)

            this.$gtm.trackEvent({
                event: 'iniciar_assinatura',
                category: "onboard",
                action: "click",
                label: "Clique no botão para iniciar a assinatura após o simulador",
                value: this.lead.average_consumption,
                noninteraction: false, // Optional
                step: this.$route.name,
                email: this.lead.email,
                phone: this.lead.phone,
            });


            this.subscribe = true
            this.$vuetify.goTo(this.$refs.focusableDiv)
      },
      showHowWorks() {
        eventBus.$emit('open-dialog_howworks')
      },
      async save(_lead, force) {
        // Remove o dialog quando a gravação é solicitada mesmo que já exista um cadastro
        if(force) this.dialog_isMember = false

        // Seta o CPF e titular informados nessa página caso não estejam no LEAD
        _lead.cpf = this.cpf.replace(/\D/g, '')
        _lead.contract_time = this.plan.contract_time
        _lead.titular = this.titular
        _lead.plan = {
            id: this.plan.id,
            contract_time: this.plan.contract_time,
            discount: this.plan.discount,
            name: this.plan.name,
            person_type: this.plan.person_type || ""
        }
        // if(!_lead.cpf ) _lead.cpf = this.cpf.replace(/\D/g, '')
        // if(!_lead.titular ) _lead.titular = this.titular

        if(!localStorage.getItem('token')) {
            await loginAnonymous()
                .then((res) => {
                    let token = res.user.za
                    if(token) localStorage.setItem('token', token)
                })
                .catch(err => console.error(err))
        }

        if((!_lead.date_of_birth || (!_lead.gender && !_lead.ID_document) || _lead.date_of_birth === '' || _lead.gender === '')) {
          await getDadosComplementares(_lead.cpf)
              .then(result => {
                if(result.data.BasicData) {
                  _lead.nome_titular = result.data.BasicData.Name || '',
                  _lead.gender = result.data.BasicData.Gender || ''
                  _lead.nacionalidade = result.data.BasicData.BirthCountry || 'Brasileiro(a)'
                  if(result.data.BasicData.AlternativeIdNumbers && result.data.BasicData.AlternativeIdNumbers.RG)
                    _lead.ID_document = result.data.BasicData.AlternativeIdNumbers
                  _lead.mother_name = result.data.BasicData.MotherName || ''
                  _lead.date_of_birth = formatBirthday(result.data.BasicData.BirthDate) || ''
                }
              })
              .catch(async error => {
                console.error(error)
              })
        }

        _lead.step = this.$route.name
        // Salva o Lead no banco de dados e no estado da aplicação

        await this.saveLead(_lead)
            .then(res => {
                let _user = res

                // Se houver id de usuario retira o id do lead e adiciona o customer_id do usuario como ID
                // Esse caso ocorre quando é solicitado a gravação mesmo que um documento de usuário já exista anteriormente
                if(!_user.customer_id) delete _user.id
                else _user.id = res.customer_id
                
                delete _user.tracking
                this.loading_btn = 'Salvando dados...'
                // Salva o User no banco de dados e no estado da aplicação
                this.saveUser(_user)
                    .then(res => {
                        if(res.code) {
                            if (res.code == 'auth/weak-password') {
                                this.error = 'A senha é muito fraca.'
                            } else if (res.code == "auth/email-already-in-use" || res.message === 'EMAIL_EXISTS') {
                                this.error = "Esse email já está sendo usado por outra conta"
                                if(res.docs && res.docs.length > 0 && res.docs[0].data() && res.docs[0].data().email)
                                    this.isMember = {customer_id: null, code: 'isMemberActivated', message: 'Verificamos que você já possui um cadastro com o e-mail ' + res.docs[0].data().email + '. Clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                                else
                                    this.isMember = {customer_id: null, code: 'isMemberActivated', message: 'Verificamos que você já possui um cadastro com o e-mail ' + _user.email + '. Clique no botão abaixo que um de nossos consultores irá ajudá-lo.'}
                                this.dialog_isMember = true
                            } else if (res.code == "auth/invalid-email") {
                                this.error = "E-mail invalido"
                            } else{
                                this.error = res.message
                            }
                            this.loading = false
                            return
                        }
                        this.loading = false
                        this.$router.push({path: '/metodos-pagamento', query: this.$route.query})
                    })
                    .catch(err => {
                        this.loading = false
                        this.error = 'Ocorreu um erro inesperado, fale com nossos consultores'
                        console.error(err)
                    })
                })
                .catch(err => {
                    this.loading = false
                    this.error = 'Ocorreu um erro inesperado, fale com nossos consultores'
                    console.error(err)
                })
      },
      async forceSave(lead, id) {
          let authData = {
              email: lead.email,
              password: md5(this.cpf)
          }
          let login = await this.login(authData)

          if(login.code === 'auth/user-not-found') {
              this.error = 'Ocorreu um erro inesperado, fale com nossos consultores'
              this.dialog_isMember = false
              this.dialog = false
              return
          }
          
          this.loading = true
          lead.customer_id = id
          this.save(lead, true)
      },
    }

}
</script>
<style scoped>
    .v-dialog--fullscreen {
        border-radius: 0;
        margin: 0;
        height: 70%;
        position: fixed;
        overflow-y: auto;
        top: 200px;
        left: 0;
    }
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
        transform: rotate(0);
        }
        to {
        transform: rotate(360deg);
        }
    }
</style>