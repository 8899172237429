import api from "../utils/api";

export const initQualification = (
  cpf,
  customer_id,
  consumer_units,
  customer_numbers,
  origin,
  form_data,
  utility_company,
  complementary_data,
  agent_email,
  partner_origin
) =>
  api.post(process.env.VUE_APP_GETQUALIFICATION_API, {
    cpf,
    customer_id,
    consumer_units,
    customer_numbers,
    origin,
    form_data,
    utility_company,
    complementary_data,
    agent_email,
    partner_origin,
  });

export const finishQualification = async (
  cpf,
  customer_id,
  qualification_ids,
  percentage_discount,
  unique_billing,
  power_plant,
  plan,
  seller,
  origin,
  agent_email
) => {
  return await api.post(process.env.VUE_APP_SETQUALIFICATION_API, {
    cpf,
    customer_id,
    qualification_ids,
    percentage_discount,
    unique_billing,
    power_plant,
    plan,
    seller,
    origin,
    agent_email,
  });
};
