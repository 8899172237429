<template>
     <v-container class="pa-0">
        <v-card
            class="d-flex justify-center align-center mb-6"
            :color="$vuetify.theme.dark ? 'white darken-3' : 'white lighten-4'"
            flat
            tile
            height="100vh"
        >
            <v-card
                class="pa-2"
                :color="$vuetify.theme.dark ? 'whbt6 darken-3' : 'white lighten-4'"
                outlined
                flat
                tile
            >
                <v-row class="justify-center pb-6">
                    <v-img 
                        src="@/assets/novo/Logo-novo.svg" 
                        max-height="100"
                        max-width="200"
                    />
                </v-row>
                <v-row class="justify-center">
                    <v-img 
                        src="@/assets/errors/surprised.png" 
                        max-height="300"
                        max-width="300"
                    />
                </v-row>
                <v-row class="justify-center text-center ma-3 pt-3">
                    <h2>Identificamos que o membro {{user && user.name ? user.name.split(' ')[0] : ''}} {{user && user.email ? ' | ' + user.email : ''}} já está cadastrado!</h2>
                </v-row>
                <v-row class="justify-center">
                    <v-card-actions>
                        <v-btn
                            color="green darken-2"
                            text
                            @click.prevent="clear"
                            target="_blank"
                        >
                            cadastrar novo
                        </v-btn>
                    </v-card-actions>
                </v-row>
                <v-row class="justify-center">
                    <v-card-actions>
                        <v-btn
                            color="blue darken-2"
                            text
                            href="https://bit.ly/whatsapp_Enercred_vendas"
                            target="_blank"
                        >
                            quero ajuda
                        </v-btn>
                    </v-card-actions>
                </v-row>
            </v-card>
        </v-card>
     </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['resetState']),
        clear() {
            localStorage.clear()
            this.resetState()
            this.$router.push({path: '/', query: this.$route.query})
        },
    }
}
</script>