<template>
  <v-container>
    <div v-if="user">
      <h3>DADOS BÁSICOS E PLANO</h3>
      <p>CPF: {{user.cpf}}</p>
      <div v-if="user && user.plan || plan">
        <p>CPF: {{user.cpf}}</p>
        <p>Plano: {{plan.name}}</p>
        <p v-if="plan.id !== 'semfidelidade'">Periodo de Fidelidade: {{ plan.canceled_time}}</p>
      </div>
    </div>
    <h3>TERMOS DE USO </h3>
    <p>A Enercredweb Sistemas Ltda., inscrita no CNPJ/MF nº 27.295.080/0001-06 ("Juntos Energia") é uma plataforma online criada para que você possa usufruir de energia com muito mais gestão própria e economia, de maneira fácil e sustentável.
    </p> 
    <p>Os Termos de Uso dos nossos serviços são muito simples e de fácil entendimento para que você saiba tudo sobre a contratação dos serviços, inclusive seus direitos e deveres.
    </p> 
    <p>A nossa missão é que você tenha a melhor experiência possível utilizando-se da nossa plataforma online e dos nossos serviços.
    </p> 
    <p>Por favor leia tudo direitinho para que não haja qualquer dúvida sobre a utilização dos serviços. Mesmo assim, se houver qualquer dúvida, estamos aqui para te ajudar, bastando entrar em contato conosco: atendimento@juntosenergia.com.br / 0800 718 7203 / WhatsApp (35) 99862-5580.
    </p> 
    <p>Ao aceitar os Termos de Uso, você aceita e se submete às condições destes termos de uso e à política de privacidade, bem como a todos os documentos anexos a estes, podendo ser alterados a qualquer tempo pela Juntos Energia.
    </p> 
    <p>Por favor, consulte os termos de uso antes de cada utilização para averiguar eventuais modificações.
    </p> 
    <h4>1 - COMO FUNCIONA A ADESÃO AOS NOSSOS SERVIÇOS
    </h4>
    <p>Os nossos serviços estão disponíveis para qualquer pessoa maior de 18 anos que queira usufruir de energia com mais economia e queira ter gestão online do seu consumo. Você deverá ter um CPF válido e um número de cliente e instalação registrados na companhia de energia elétrica para usufruir dos nossos serviços.
    </p>
    <p>Para poder contar com os nossos serviços, basta efetuar o cadastro dentro da plataforma mediante o preenchimento de alguns dados pessoais solicitados, tais como: nome completo, estado civil, profissão, idade, CPF, RG, endereço completo, e-mail válido e telefone celular.
    </p>
    <p>Feito o cadastro, vamos precisar que você faça a confirmação do cadastro via e-mail e nos envie uma cópia ou foto do documento de registro do titular da fatura de energia, bem como uma conta de energia com vencimento nos últimos 3 meses, a qual receberá o desconto.
    </p>
    <p>Ao enviar todas as informações e documentos, você declara que as mesmas são verdadeiras e nos isenta de qualquer responsabilidade por inveracidade ou falsidade, respondendo perante a Juntos Energia e terceiros por qualquer dano que isso causar.
    </p>
    <p>De posse de todas essas informações e documentos, a nossa equipe vai analisar tudo e aprovar ou reprovar a adesão, sempre utilizando de critérios técnicos. Os critérios técnicos são baseados na avaliação do seu histórico de adimplência com a concessionária de energia, na constância e volume de energia consumida, bem como no tipo de tarifação recebida por você.
    </p>
    <p>Havendo reprovação do cadastro, nós enviaremos um e-mail detalhando os motivos. Em caso de aprovação do cadastro, nós enviaremos este Termo de Uso para sua análise e assinatura digital.
    </p>
    <p>Quando da aprovação e aceite dos Termos de Uso, você será um cooperado da COOPERATIVA DE CONSUMIDORES DE ENERGIA ENERCRED COOP LTDA., pessoa jurídica de direito privado, com sede na Cidade de Pedralva, Estado de Minas Gerais, na Rua Coronel Machado n°. 290, bairro Centro, inscrita no CNPJ sob o nº 27.305.300/0001-35 (“Enercred Coop”), responsável pela gestão e administração da usina fotovoltaica, podendo usufruir de todos os nossos serviços e economia de energia.
    </p>
    <p>Importante lembrarmos que a sua vinculação à Juntos Energia é condição obrigatória para sua utilização dos nossos serviços, pois se trata de exigência determinada em nossa legislação (Resolução Normativa nº 482, de 17 de Abril de 2021) pela Agência Nacional de Energia Elétrica – ANEEL.
    </p>
    <p>Você receberá um boleto bancário da Juntos Energia a partir do primeiro ciclo de injeção de créditos de energia em sua instalação, com vencimento em data previamente definida pela Juntos Energia e sempre de maneira online, devendo pagar as faturas e boletos recebidos todos os meses.
    </p>
    <p>Importante: Nos 2 (dois) primeiros meses de uso dos nossos serviços você poderá continuar recebendo boleto da companhia de energia da sua região, juntamente com o boleto bancário enviado pela Juntos Energia. Contudo, informamos que você não deverá realizar o pagamento da fatura da companhia de energia, pois esse trâmite será de nossa responsabilidade. Após esse período você passará a receber somente o nosso boleto.
    </p>
    <p>A Juntos Energia realiza a intermediação dos serviços junto a companhia de energia da sua região. Isso significa dizer que quaisquer regras, tarifas, taxas, contribuições, tributos etc. estabelecidos pela companhia de energia serão repassados a você. Ao aceitar os Termos de Uso você nos autoriza a realizar toda intermediação junto a companhia de energia da sua região visando sempre lhe oferecer o melhor serviço.
    </p> 
    <p>Importante informarmos também que, em caso de inadimplemento do boleto da Juntos Energia, implicará na não quitação das obrigaçoes junto a companhia de energia e o consequente desligamento da sua energia sem qualquer ônus à Juntos Energia.
    </p>
    <p>Você pode nos solicitar cópia do comprovante de quitação das obrigações junto a companhia de energia elétrica que enviaremos a você sempre  observando o prazo da companhia de energia local.
    </p>
    <p>Todas as informações relativas ao seu contrato, como, por exemplo, consumo de energia, valores a serem pagos, forma de pagamento, problemas com a disponibilização de energia, dentre outros, serão realizadas na plataforma online – juntosenergia.com.br.
    </p>
    <div v-if="plan" v-html="plan && plan.cancelation_conditions ? plan.cancelation_conditions.contract_clause: ''"/>
    <div v-else>
      <h4>2 – QUAL É O PRAZO DOS SERVIÇOS E QUANDO PODE SER CANCELADO
      </h4>
      <p>O tempo de duração dos serviços disponibilizados pela Juntos Energia e/ou Enercred Coop é indeterminado. 
      </p>
      <p>A Juntos Energia e/ou Enercred Coop poderá cancelar os serviços, unilateralmente e imotivadamente, mediante aviso prévio à você, por escrito, de 30 (trinta dias), sem multa ou indenização.
      </p>
      <p>Você poderá cancelar unilateralmente e imotivadamente os serviços, mediante aviso prévio à Juntos Energia e Enercred Coop, por escrito, de 90 (noventa) dias, devendo arcar com os pagamentos mensais devidos durante referido período. O desrespeito ao prazo de aviso prévio antes referido constituirá inadimplemento contratual.
      </p>
      <p>Constituem condições resolutivas as que, se verificadas, qualquer uma delas, a qualquer tempo, encerram a relação contratual, sem o dever de pagamento de multa, indenização ou ressarcimento de qualquer forma pela Juntos Energia ou Enercred Coop a você:
      </p>
      <p>a) A não obtenção da aprovação de conexão da Usina à rede de distribuição pela concessionária de energia local em até 06 (seis) meses após a assinatura online dos Termos de Uso;
      </p>
      <p>b) A não obtenção da licença operação (ou equivalente), no prazo máximo de 06 (seis) meses, contados da assinatura online dos Termos de Uso;
      </p>
      <p>c) Qualquer decisão judicial e/ou governamental suspendendo, cancelando ou proibindo as atividades da Usina;
      </p>
    </div>
    <h4>3 – DA COOPERATIVA
    </h4>
    <p>Para usufruir dos serviços é indispensável a sua vinculação a Enercred Coop, cuja determinação advêm da ANEEL, sendo a legislação encontrada no link www2.aneel.gov.br/cedo/ren2021482.pdf.
    </p>
    <p>O estatuto da Enercred Coop se encontra no link <a href="https://juntos.eco/estatuto-cooperativa-mg" target="_blank">https://juntos.eco/estatuto-cooperativa-mg</a>, onde constam todos os direitos e obrigações de cada cooperados disponível para acesso e leitura, sendo condição necessária para sua perfeita compreensão dos serviços.
    </p>
    <h4>4 - VALORES A SEREM PAGOS POR VOCÊ
    </h4>
    <p>Os valores de utilização dos serviços serão definidos individualmente, pois é proporcional a quantidade de créditos de energia efetivamente injetada mensalmente na sua fatura.
    </p>
    <p>A utilização dos créditos injetados pode implicar na cobrança da taxa de disponibilidade pela companhia de energia local, cuja taxa é atrelada ao tipo de ligação em que o padrão de energia está conectado e é determinada e devida à companhia de energia local, conforme Resolução Normativa nº 482 de 2021.
    </p>
    <p>O seu desconto é calculado considerando os valores do kWh praticados pela companhia de energia no mês multiplicado pelo volume de créditos de energia injetada, já inclusos os impostos.
    </p>
    <p>O serviço será cobrado no momento da utilização do benefício, isto é, você só paga no momento em que efetivamente recebe o desconto em sua fatura de energia.
    </p>
    <!-- <p>O reajuste das tarifas ocorrerá sempre na data de aniversário da sua adesão e será pelo IGPM/FGV – a cada ano ou em período menor assim definido pela legislação em vigor. 
    </p> -->
    <p>O reajuste da tarifas seguira o reajuste realizado pela concessionária.</p>
    <p>Quaisquer alterações nos percentuais das alíquotas dos tributos incidentes sobre os serviços, bem como a criação, extinção, modificação, isenção, redução desses tributos, direitos, encargos de qualquer espécie, instituição de estímulos fiscais de qualquer natureza e eventuais reajustes extraordinários por parte da companhia de energia local, que de qualquer forma incidam ou venham a impactar o valor dos serviços, ocorridos entre a data de assinatura dos Termos de Uso e a data do respectivo faturamento, ensejarão a revisão dos serviços a fim de adequá-lo às modificações havidas.
    </p>
    <p>Como forma de fomentar novos negócios e/ou ações de responsabilidade sociais, os descontos poderão ser repassados pela Juntos Energia à fundações, associações, empresas parceiras e quaisquer outras pessoas físicas e/ou jurídicas, cujo consentimento será fornecido por Você dentro da plataforma online.
    </p>
    <h4>5 - FATURAMENTO E DA FORMA DE PAGAMENTO
    </h4>
    <p>A fatura detalhada da cobrança juntamente com um único boleto dos serviços da Juntos Energia e energia consumida junto a companhia de energia local será encaminhada a você mensalmente, até o décimo quinto dia após a leitura de energia realizada pela concessionária, e terá como data de vencimento e forma de pagamento as indicadas em sua conta online.
    </p>
    <p>A cobrança dos serviços só será devida a partir do momento em que você passar a usufruir dos serviços e será calculada de acordo com a disponibilização de energia limpa demandada, podendo variar mês a mês.
    </p>
    <p>Fica caracterizada a sua mora quando deixar de liquidar quaisquer das cobranças devidas, parcial ou total, até a data do vencimento. No caso de mora no pagamento, incidirão sobre os valores inadimplidos os acréscimos de correção monetária, multa de 2% sobre a fatura e juros de mora de 1% a.m. calculados pro rata die.
    </p>
    <p>Em caso de inadimplemento, a Juntos Energia ou Enercred Coop poderá imediatamente inscrever seu nome nos órgãos de proteção ao crédito – SPC / SERASA, bem como protestar os títulos.
    </p>
    <h4>6 – DOS DEVERES DA ENERCRED COOP
    </h4>
    <p>Observar e cumprir rigorosamente toda a legislação vigente e aplicável ao objeto dos Termos de Uso;
    </p>
    <p>Obter e manter válidas e vigentes, durante toda a vigência dos Termos de Uso, todas as licenças necessárias para implantação, operação e exploração da Usina, bem como todas aquelas necessárias ao cumprimento das obrigações assumidas nos Termos de Uso;
    </p>
    <p>Garantir o pleno funcionamento da Usina em condições técnicas de operação, de forma a permitir seu aproveitamento na modalidade de geração compartilhada em seu favor.
    </p>
    <h4>7 – DOS DEVERES DA JUNTOS ENERGIA 
    </h4>
    <p>Observar e cumprir rigorosamente toda a legislação vigente e aplicável ao objeto dos Termos de Uso;
    </p>
    <p>Enviar todas as faturas e boletos mensalmente com antecedência mínima de 05 dias para seu pagamento;
    </p>
    <p>Prestar todo suporte técnico online para quaisquer dúvidas ou problemas em relação aos serviços.
    </p>
    <h4>8 – DOS SEUS DEVERES 
    </h4>
    <p>Pagar pontualmente os serviços;
    </p>
    <p>Informar eventuais alterações da unidade consumidora sempre que tais alterações impactarem no objeto dos serviços, com no mínimo 90 (noventa) dias de antecedência. Você fica ciente de que a inobservância dessa comunicação no prazo retro previsto poderá acarretar a perda de créditos de energia que lhe seriam destinados, sem qualquer multa ou indenização por nossa parte;
    </p>
    <p>Se abster de influenciar, direta ou indiretamente, no funcionamento e operação da Usina.
    </p>
    <h4>9 - CASO FORTUITO E FORÇA MAIOR
    </h4>
    <p>Nos termos do artigo 393 do Código Civil Brasileiro, as obrigações de cada uma das Partes não serão exigíveis durante a ocorrência comprovada de caso fortuito e/ou de força maior, assim considerado qualquer ato, evento ou circunstância inevitável e imprevisível não causado por erro, culpa ou dolo de qualquer das Partes, de seus empregados, funcionários, prepostos, subcontratados ou fornecedores e cujos efeitos não possam ser superados pelo exercício de esforços imediatos e diligentes.
    </p>
    <p>A Parte afetada pelo evento de caso fortuito ou de força maior deverá tomar as medidas possíveis para minimizar seus efeitos, bem como notificar a outra Parte quando se tornar razoavelmente aparente que a execução dos serviços sofrerá qualquer tipo de atraso, mas nunca após o prazo de 5 (cinco) dias contados da materialização do evento, com as informações necessárias à caracterização das circunstâncias, tais como: tipo de evento, horário de sua ocorrência, sua exata localização, efeitos e consequências sobre as obrigações das Partes e providências corretivas imediatamente tomadas.
    </p>
    <p>Na ocorrência de fatos ou eventos de caso fortuito ou força maior, cada uma das Partes arcará com todos os prejuízos diretos e indiretos dele resultantes, somente sendo permitida a extinção do presente Termos de Uso, nos termos da Cláusula 10, abaixo.
    </p>
    <h4>10 - EXTINÇÃO, DAS PENALIDADES E DA RESPONSABILIDADE
    </h4>
    <p>Os Termos de Uso poderão ser resolvido, sem qualquer penalidade ou ônus, quando:
    </p>
    <p>a) houver impossibilidade de manutenção do modelo de compensação de crédito de energia elétrica em razão de alteração em leis, resoluções, decisões judiciais/governamentais ou qualquer outra norma superveniente;
    </p>
    <p>b) ocorrer evento de caso fortuito ou de força maior, devidamente comprovado, impeditivo de execução do Contrato por mais de 180 (cento e oitenta) dias, nos termos da Cláusula 9;
    </p>
    <p>c) inadimplemento das obrigações de qualquer das Partes, não sanadas no prazo máximo de 30 (trinta) dias ou em prazo inferior definido no presente, a contar do recebimento pela Parte inadimplente de notificação, por escrito, enviada pela Parte adimplente, instando-a a adimplir a obrigação;
    </p>
    <p>d) não renovação ou prorrogação da permissão perante a Concessionária de Energia Local e/ou das licenças ambientais perante os órgãos ambientais competentes;
    </p>
    <p>f) falência, dissolução, recuperação judicial/extrajudicial ou insolvência de qualquer Parte.
    </p>
    <p>Eventuais penalidades por qualquer das hipóteses do presente item serão aplicadas especificamente nas cláusulas que assim definirem.
    </p>
    <p>Em qualquer hipótese de encerramento dos serviços, caberá à Enercred Coop requerer o desligamento da sua unidade consumidora do Sistema de Compensação de Energia Elétrica da Usina junto à Concessionária de Energia Local.
    </p>
    <p>A extinção dos serviços, independentemente do motivo, não isenta as Partes das obrigações devidas até a data da extinção, incluindo valores vencidos ou a vencer até o exato momento da extinção.
    </p>
    <h4>11 - MANDATO
    </h4>
    <p>Em conformidade com o art. 684 do Código Civil, você outorga à Enercred Coop, de forma irrevogável e irretratável, durante a vigência dos serviços, sendo revogados após sua rescisão ou término, poderes gerais necessários para que a Enercred Coop a represente em todo e qualquer assunto junto à Concessionária de Energia Local para fins de sua inclusão como beneficiária de geração compartilhada e também para ter acesso e poder consultar livremente suas faturas de energia perante a Concessionária de Energia Local. O presente Mandato poderá ter sua vigência estendida por até 60 (sessenta) meses ou novo prazo que venha a ser estabelecido pelo art. 61 da REN ANEEL n. 482/2012, caso você possua créditos em quantidade de energia ativa a ser consumida após a rescisão ou término deste Contrato.
    </p>
    <h4>12 – LICENÇA DE USO DA PLATAFORMA E PROPRIEDADE INTELECTUAL
    </h4>
    <p>A Juntos Energia é detentora de toda propriedade intelectual da plataforma online da Juntos Energia. Para você usufruir dos serviços, concedemos a você a licença de uso da nossa plataforma online. 
    </p>
    <p>A licença de uso é limitada, uma vez que você não poderá alugar, coletar dados, códigos fontes e informações; vender, revender, copiar, fazer engenharia reversa, duplicar, reproduzir, explorar, modificar e de qualquer outra forma utilizar, desenvolver ou violar a licença de uso ora concedida.
    </p>
    <p>A marca, nome empresarial ou nome de domínio, bem como os logos, marcas, insígnias, software, hardware, bancos de dados, redes, códigos fontes, informações, documentos e arquivos por nós utilizados são de nossa propriedade e estão protegidos pelas leis e pelos tratados internacionais de direito autoral, de marcas, de patentes, de modelos e de desenhos industriais. 
    </p> 
    <p>O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, mesmo em caso de término dos serviços, ficando você responsável civil e criminalmente no seu sentido mais extensivo e abrangente possível, bem como todas as perdas e danos, diretas e indiretas, lucros cessantes, honorários de advogados e custos envolvidos incorridos por nós.
    </p> 
    <p>A licença será válida até enquanto você se mantiver cadastrado em nossa plataforma e utilizar dos nossos serviços.
    </p>
    <h4>13 - PROTEÇÃO E PRIVACIDADE DE DADOS
    </h4>
    <p>Considerando que o tratamento de dados pessoais, sob as suas mais diversas espécies, é inerente as atividades objeto do presente instrumento, estas serão realizadas e/ou gerenciadas pelas Partes em estrita observância e cumprimento a Lei nº 12.965/14, regulamentada pelo Decreto nº 8.771/16 (Lei do Marco Civil da Internet), e da Lei nº 13.709/18 (Lei de Proteção de Dados Pessoais), e demais diplomas legais vigentes e/ou que vierem a viger durante ou após o prazo deste Contrato.
    </p>
    <p>As bases de dados são de propriedade e de seu controle e será por você compartilhada conosco. Assim, na qualidade de operador dos seus dados pessoais, nós podemos tratar os seus dados pessoais da seguinte forma, CONCORDANDO EXPRESSAMENTE, NESTE ATO, COM TAIS TRATAMENTOS DE DADOS:
    </p>
    <p>a) Tratar e usar os dados pessoais nos termos legalmente permitidos;</p>
    <p>b) Tratar os dados de modo compatível com as finalidades para os quais tenham sido coletados, que tenha acesso no decorrer da relação jurídica e/ou compartilhados visando cumprir a execução de contrato junto a você para fornecer energia limpa;</p>
    <p>c) Manter registro das operações de tratamento de dados pessoais realizadas.</p>
    <p>d) Conservar os dados apenas durante o período necessário para a finalidade da coleta e do tratamento posterior, garantindo a sua confidencialidade.</p>
    <p>e) Observar as regras legais e regulatórias incidentes sobre armazenamento de dados por computação em nuvem, notadamente as regras de transferência internacional de dados.</p>
    <p>f) Compartilhar com terceiros e/ou subcontratados os dados que integrarem a base de dados compartilhada por você, incluindo, mas não se limitando as concessionárias de energia elétrica, empresas de cobrança de crédito inadimplido, empresas de armazenamento de dados em nuvem, empregados, prestadores de serviços, Usinas, empresas privadas, fundações, associações, prefeituras, sociedades de economia mista, órgãos públicos em geral e quaisquer outras pessoas físicas, jurídicas e/ou entidades governamentais, com a finalidade de fornecer energia limpa a você, para cumprimento de obrigação legal ou regulatória, conceder promoções, benefícios e/ou descontos nas contas de energia limpa, bem como outras parcerias pela Juntos Energia para melhorar a sua experiência na plataforma;</p>
    <p>g) Informar imediatamente a você a ocorrência de incidente de segurança ou sua suspeita, devendo prestar toda a colaboração necessária a qualquer investigação que venha a ser realizada por nós e/ou por autoridades de proteção de dados, independentemente de expor ou não algum dado a efetivo vazamento e independentemente do titular ter sofrido ou não um dano concreto.</p>
    <p>h) Reter, ao término da relação jurídica com você, quando necessário e aplicável, os seus dados pessoais com a finalidade de cumprir obrigação legal ou regulatória (art. 16, I, da LGPD), exercício regular de direitos ou uso exclusivo da Juntos Energia como controlador de dados desde que anonimizados (art. 16, IV, da LGPD), sendo que, cumprido isso, nós excluiremos os dados pessoais permanentemente da nossa base de dados.</p>
    <h4>14 - LIMITAÇÃO DA RESPONSABILIDADE DA JUNTOS ENERGIA E ENERCRED COOP
    </h4>
    <p>Nós não concedemos garantias de qualquer tipo, expressas, implícitas ou de outra forma com relação aos serviços de energia limpa. Ademais, o uso da plataforma online não é garantia dos serviços da concessionária.</p>
    <p>Não declaramos ou garantimos que o fornecimento de energia, serviço e/o software será ininterrupto, seguro ou livre de erros, quaisquer defeitos ou erros no serviço serão corrigidos.</p>
    <p>Em nenhuma circunstância, a Juntos Energia e Enercred Coop, suas controladoras, controladas, afiliadas, subsidiárias, empresas do mesmo grupo econômico, funcionários, licenciadores ou prestadores de serviços serão responsáveis por quaisquer danos indiretos e diretos, inclusive, entre outros, lucros cessantes, incorrida direta ou indiretamente, ou perda de dados, uso e outras perdas intangíveis, resultantes de problemas com, incluindo, mas não se limitando a fornecimento, parcial ou total, de energia à você; a concessionária; plataforma online.</p>
    <p>Em nenhuma circunstância, a nossa responsabilidade em relação a você e/ou terceiros por todas as reivindicações relacionadas ao objeto do presente contrato excederá o valor pago pela conta de consumo de energia do último mês, se for o caso, enquanto tiver uma conta ativa.</p>
    <h4>15 – COOKIES DO SITE E DE NAVEGAÇÃO</h4>
    <p>Cookies são arquivos de texto enviados pela Juntos Energia ao seu computador e que nele ficam armazenados, com informações relacionadas à navegação online.
    </p>
    <p>Por meio dos cookies, podem ser armazenados, por exemplo, dados sobre o dispositivo por você utilizado, bem como seu local e horário de acesso à nossa plataforma online.
    </p>
    <p>É importante ressaltar que nem todo cookie contém informações que permitem a identificação, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o sistema seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.
    </p>
    <p>A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades da nossa plataforma online, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências que eventualmente tiverem sido salvas, prejudicando sua experiência.
    </p>
    <p>Alguns dos nossos parceiros comerciais podem usar identificador de celular e/ou cookies em nossos aplicativos (por exemplo, os anunciantes). Contudo, não temos acesso ou controle sobre estes cookies.
    </p> 
    <p>Caso não queira que seu navegador aceite tal identificador de celular e/ou cookies, realize as configurações necessárias no seu navegador para retirar seu consentimento. 
    </p>
    <h4>16 - DAS DISPOSIÇÕES GERAIS
    </h4>
    <p>As Partes declaram e garantem, para todos os fins e efeitos:
    </p>
    <p>a) Ter ciência das obrigações, responsabilidades e riscos assumidos em razão da celebração e execução do Contrato, reconhecendo-os como manifestamente proporcionais e justas;
    </p>
    <p>b) Ter obtido e avaliado criteriosamente todas as informações e circunstâncias necessárias e convenientes relativas a contingências que possam influenciar ou afetar, de qualquer forma, a execução do objeto do Contrato.
    </p>
    <p>Estes Termos de Uso constitui o entendimento integral das Partes, prevalecendo sobre todas e quaisquer correspondências, entendimentos ou outros documentos anteriores firmados relativos ao mesmo objeto, vinculando as Partes e obrigando seus eventuais respectivos sucessores.
    </p>
    <p>Nós podemos ceder ou transferir sua posição nos Termos de Uso, em seu Contrato ou quaisquer benefícios, interesses, direitos e/ou obrigações dele decorrentes, no todo ou em Parte, independentemente de sua anuência prévia.
    </p>
    <p>Você somente poderá ceder ou transferir sua posição nos Termos de Uso, em seu Contrato ou quaisquer benefícios, interesses, direitos e/ou obrigações dele decorrentes, no todo ou em parte, caso obtenha anuência prévia e por escrito da Juntos Energia e Enercred Coop.
    </p>
    <p>A nulidade declarada de qualquer uma das cláusulas ou condições ora pactuadas não acarretará a nulidade do Contrato, que permanecerá válido e exigível em todos os seus demais termos e condições.
    </p>
    <p>Não haverá nenhum relacionamento entre as Partes de qualquer natureza (trabalhista, societária e/ou qualquer outra) que não o contemplado pelo Contrato. Nenhuma das Partes tem qualquer direito, poder ou autoridade de assumir qualquer obrigação ou responsabilidade em nome ou por conta da outra.
    </p>
    <p>Qualquer aviso ou comunicação de uma Parte à outra será feita por escrito e poderá ser entregue por correios, correio eletrônico (e-mail) ou cartório. O contato de nosso suporte é o atendimento@juntosenergia.com.br / 0800 718 7203 / (35) 99862-5580.
    </p>
    <p>Caso haja mudança posterior na legislação e normas do setor elétrico brasileiro, inclusive nos Procedimentos de Distribuição - PRODIST ou na Resolução Normativa n 482, de 17/04/2012, que venham alterar substancialmente as condições ora pactuadas, as Partes desde já concordam em negociar de boa-fé o seu aditamento, visando à manutenção das bases objetivas do Contrato, sendo certo que, na impossibilidade de acordo para preservá-lo, poderá ser resolvido, sem quaisquer nus ou multas, por qualquer das Partes, nos termos da Cláusula 8 deste Contrato.
    </p>
    <p>Os Termos de Uso constituem título executivo extrajudicial, na forma do artigo 784, inciso III, do Código de Processo Civil, inclusive para efeito de cobrança de todos e quaisquer valores decorrentes das obrigações aqui contempladas, abrangendo pagamentos, ressarcimento dos valores já desembolsados, encargos financeiros, multas, juros moratórios e remuneratórios, despesas cartorárias e outros custos.
    </p>
    <p>As Partes elegem o foro da comarca da companhia de energia como o único competente para dirimir qualquer controvérsia do presente Contrato, renunciando qualquer outro, por mais privilegiado que seja. 
    </p>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(["plan", "user"])
    }
}
</script>