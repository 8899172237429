<template>
    <v-container fluid class="ma-0">
        <v-row>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <steps :step="2" :total="step_total" :itens="steps"/>
                                            <lead-form class="pa-6"/>
                    
                        </v-col>
                        <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
                    </v-row>
                </v-container>
</template>
<script>
import LeadForm from '../components/Simulador/LeadForm.vue'
import Steps from '../components/Steps'
import { mapState } from 'vuex'

import store from '../store'
import { convertRDStation } from '../services/rdstation'
export default {
    components: {
        LeadForm,
        Steps,

    },
    mounted() {
        
        if(this.lead) {
            this.step_total = 2
            this.average_consumption = this.lead.average_consumption,
            this.person_type = this.lead.person_type
            this.steps =  ['Lista de Espera']
            if(this.$route.query.utility) {
                this.lead.utility = this.$route.query.utility
                this.utility = this.$route.query.utility
            }
            if(this.$route.query.average_consumption) {
                this.lead.average_consumption = this.$route.query.average_consumption
                this.average_consumption = this.lead.average_consumption
            }
            if(this.$route.query.person_type) {
                this.lead.person_type = this.$route.query.person_type 
                this.person_type = this.lead.person_type
            }
        } else {
            if (this.$route.query.average_consumption && this.$route.query.person_type) {
                let lead = {}
                lead.average_consumption = this.$route.query.average_consumption
                lead.person_type = this.$route.query.person_type
                this.average_consumption = this.$route.query.average_consumption 
                this.person_type = this.$route.query.person_type
                store.commit('setLead', lead)
            } else {
                this.$router.push({path: '/', query: this.$route.query})
            }
        }
        this.$gtm.trackEvent({
            event: 'step1_cadastro',
            category: "onboard",
            action: "pageview",
            label: "Acesso a página de preenchimento dos dados pessoais para cadastro",
            value: this.lead.average_consumption,
            email: this.lead.email,
            noninteraction: false, // Optional
            step: this.$route.name,
            phone: this.lead.phone,
            person_type: this.lead.person_type
        });
        convertRDStation('step1_cadastro', this.lead)
            .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err.error))
            .then(() => console.log('Conversão step1_cadastro gravada com sucesso no RD Station'))

    },
    computed: {
        ...mapState(['lead'])
    },
    data: () => ({
        steps: ['Dados de Simulação', 'Sua Economia'],
        step_total: 3,
        utility: 'cemig',
        image: 'https://firebasestorage.googleapis.com/v0/b/enercred.appspot.com/o/web%2Flogin%2Fusina1.png?alt=media&token=d885f729-6d59-4fe7-bad4-17dc28b794d0',
        average_consumption: null,
        person_type: null
    })
}
</script>
<style>
    .bg {
        background: url("../assets/bg_onboarding.png") repeat center center;
        /* position: absolute; */
        opacity: 1;
        /* background: linear-gradient(180deg, rgb(5, 23, 77) -10%, #27408B 80%, #27408B 100%); */
        object-fit: cover;
        width: 100%;
        min-height: 100vh;
    }
</style>