<template>
    <div class="text-center">
      <v-dialog
        v-model="dialog_como_funciona"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            small
            class="my-3"
          >
            <div class="text-decoration-underline text-none" >
              Entenda como funciona
          </div>
          </v-btn>
        </template>
  
        <v-card>
          <!-- <v-card-title class="text-h6 grey lighten-2">
            Como funciona a Juntos
          </v-card-title> -->
          <iframe width="100%" height="198" src="https://www.youtube.com/embed/EI6rTIbp-r4" title="Juntos Energia" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p class="text-h6 pa-3 primary--text font-weight-bold text-center">Como receber energia sem instalar equipamentos?</p>
          <v-card-text class="text-justify">Conectamos produtores de energia limpa e sustentável a apês, casas e empresas. Enviamos a energia para a rede da sua concessionária e eles entregam para você. Não é necessário investir em placas e pagar nada por essa portabilidade. É digital e gratuita!</v-card-text>
          <v-img src="../../assets/howworks/como_funciona.png" class="center"></v-img>
          <v-divider class="mt-5 mb-5"></v-divider>
          <div>
            <p
              class="text-h6 py-0 mb-0 primary--text font-weight-bold text-center"
            >
              {{
                plan.contract_time > 0
                  ? 'Fidelidade de ' + plan.canceled_time
                  : 'Sem fidelidade'
              }}
            </p>
            <v-card-text
              v-if="
                plan.id == 'liquidaenergiapj' || plan.id == 'liquidaenergiapf'
              "
              class="text-justify"
              >{{
                plan.cancelation_conditions.contract_canceled_clause
              }}</v-card-text
            >
            <v-card-text v-else class="text-justify"
              >Caso queira cancelar, só precisa fazer um aviso prévio de 60 dias
              para que a gente possa fazer a reversão da portabilidade na
              concessionária. É o prazo exigido pela concessionária para o
              desligamento. Mas pode confiar! O atendimento é por telefone ou
              Whatsapp e não tem perrengue. Queremos te provar que é possível
              receber um bom atendimento em um prestador de serviços de energia!
              (a gente não tava acostumado com isso, né?)</v-card-text
            >
          </div>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_como_funciona = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
//   import { mapState } from 'vuex'
  import { eventBus } from '../../main'
  export default {
      props: ['plan'],
      data () {
          return {
            dialog_como_funciona: false,
          }
      },
      mounted () {
          eventBus.$on('dialog_como_funciona', async () => {
              this.dialog_como_funciona = true
          })
      },
  }
  </script>