<template>
    <v-container fluid fill-height>
        <v-row align="center" class="about">
                <v-col cols="12" sm="12" md="6" lg="6" class="pa-lg-16 pl-md-12">
                    <h1>Democratização do acesso à energias limpas.</h1>
                    <h2 class="font-weight-light darkblue--text pt-12">A Juntos Energia faz com que consumidores residenciais e PME’s possam aderir às fontes renováveis de forma simplificada. Dessa forma, todos podem apoiar à preservação do Planeta e reduzir gastos de eletricidade com apenas alguns cliques.
                    Se você tem um consumo médio superior a R$ 150,00, faça uma simulação da sua ECONOMIA.</h2>
                    <div class="text-center pt-12"><v-btn color="primary" href="#simular" large rounded class="pl-16 pr-16">{{config.call_to_action || 'SIMULE AGORA'}}</v-btn></div>
                </v-col>
        </v-row>
    </v-container>
</template>
<script>
  export default {
    props: ['config']
  }
</script>
<style scoped>
    .about {
        background: url('https://enercred.com.br/wp-content/themes/enercred-1/assets/min-images/servico/mockup-celular.png') center center no-repeat;
        background-size: cover;
        min-height: 100vh;
        width: 100%;
    }
</style>