import { firestore } from "../firebase/firestore";
import { firebaseAuth } from "../firebase/firebaseAuth";
import { createCustomer } from "./pagarme";
import { getActiveConsumerUnitByCustomerId } from "./consumerUnits";
import md5 from "md5";

export const getUser = (id) => {
  return firestore.collection("users").doc(id).get();
};

export const getUserByEmail = async function (email) {
  const user = await firestore
    .collection("users")
    .where("email", "==", email)
    .limit(1)
    .get();

  if (user.empty) return new Error("Usuário não encontrado");
  else {
    return user.docs[0].data();
  }
};

export const getUserByCPF = (cpf) => {
  return firestore.collection("users").where("cpf", "==", cpf).limit(1).get();
};

export const getActiveUserByCPF = async (cpf) => {
  let user = await firestore
    .collection("users")
    .where("cpf", "==", cpf)
    .limit(1)
    .get();
  if (user.empty) return false;
  else {
    let uc = await getActiveConsumerUnitByCustomerId(user.docs[0].id);
    if (uc.empty) return false;
    else return user;
  }
};

export async function storeUser(id, data) {
  let ref = firestore.collection("users").doc(id);
  data.updated_at = new Date();
  return ref
    .get()
    .then(async (user) => {
      if (user.exists) {
        if (user.data() && !user.data().buyer_id) {
          const dataPagarme = {
            uid: id,
            type: data.person_type === "pf" ? "individual" : "corporation",
            country: "br",
            name: data.name,
            email: data.email,
            phone:
              data.phone.charAt(0) === "+"
                ? data.phone
                : "+" + data.phone || "",
            // birthday: data.date_of_birth,
            // gender: data.gender,
            cpf: data.cpf,
          };
          let pagarme_user = await createCustomer(dataPagarme);
          if (pagarme_user && pagarme_user.data.extensions)
            console.error("Erro ao criar usuário no Pagarme ");
          else data.buyer_id = pagarme_user.data.id;
        }
        return ref
          .update(data)
          .then(() => {
            return ref.get();
          })
          .catch((error) => {
            console.error("Erro ao atualizar usuário");
            return error;
          });
      } else {
        data.created_at = new Date();
        if (!data.status) data.status = "ONBOARDING";
        data.customer_id = id;
        data.id = id;
        if (!data.buyer_id) {
          const dataPagarme = {
            uid: id,
            type: data.person_type === "pf" ? "individual" : "corporation",
            country: "br",
            name: data.name,
            email: data.email,
            // phone: data.phone || '',
            phone:
              data.phone.charAt(0) === "+"
                ? data.phone
                : "+" + data.phone || "",
            birthday: data.date_of_birth || null,
            gender: data.gender || null,
            cpf: data.cpf,
          };
          // let pagarme_user = await createCustomer(dataPagarme)
          createCustomer(dataPagarme)
            .then((pagarme_user) => {
              if (pagarme_user && pagarme_user.data.extensions)
                console.error("Erro ao criar usuário no Pagarme");
              else
                data.buyer_id =
                  pagarme_user && pagarme_user.data
                    ? pagarme_user.data.id
                    : null;
            })
            .catch((err) => {
              console.error("Error createCustomer : ", err);
            });
        }
        return ref
          .set(data)
          .then(() => {
            return ref.get();
          })
          .catch((error) => {
            console.error("Erro ao atualizar usuário. ", error);
            return error;
          });
      }
    })
    .catch((error) => {
      console.error("Erro ao atualizar usuário. Err: ", error);
      return error;
    });
}

export const createAuthUser = (data) => {
  return firebaseAuth
    .createUserWithEmailAndPassword(data.email, md5(data.cpf))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("createAuthUser: ", err.message);
      return err;
    });
};

export const getAuthUser = (data) => {
  return (
    firebaseAuth
      .signInWithEmailAndPassword(data.email, md5(data.cpf))
      // .fetchSignInMethodsForEmail(email)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err.message);
        return err;
      })
  );
};

export const loginAnonymous = async () => {
  try {
    const res = await firebaseAuth.signInAnonymously();
    return res;
  } catch (err) {
    console.error(err.message);
    return err;
  }
};

export const changePassword = (password) => {
  return firebaseAuth.currentUser.updatePassword(password);
};
