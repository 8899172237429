<template>
  <v-app>
    <v-main>
      <router-view v-if="!this.loading" />
      <splash-page v-if="this.loading" />
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapState } from "vuex";
import store from "./store";
import { getUTM, getTracking } from "./services/tracking";
import SplashPage from "./components/SplashPage.vue";
import { getCEP } from "./services/cep";
import { formatPhone } from "./utils/phone";
import { convertRDStation } from "./services/rdstation";
// import { wecleverLead } from './services/weclever'
export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    SplashPage,
  },
  async created() {
    try {
      this.loading = true;
      // Pega os dados ambientais para apresentar na tela de simulador
      if (!this.enviroments) {
        this.getBenefits();
      }

      // Se for uma Landing Pega os dados customizados da Landing Page
      let id = this.$route.params.id;
      if (id) {
        await this.getDocConfig(id).catch((err) => {
          console.error(err.message);
        });
      }

      let query = this.$route.query ? this.$route.query : null;

      // query = query ? query.replace(/amp;/g,'') : null

      // Pega Parametros de UTM

      // Verifica se o Lead já foi passado de uma tela de LP completa
      // console.log('utility: ', query.utility)
      // console.log('average_consumption: ', query.average_consumption)
      // console.log('person_type: ', query.person_type)
      // console.log('name: ', query.name)
      // console.log('email: ', query.email)
      // console.log('phone: ', query.phone)
      // console.log('zipcode: ', query.zipcode)
      // Verifica se veio de indicação de amigos

      if (this.$route.name !== "changePaymentMethod") {
        let utm = getUTM(query);
        store.commit("setUTM", utm);

        // Grava o tracking do caminho do membro
        let tracking = await getTracking(query);
        store.commit("setTracking", tracking);
        // Verifica se já possui um Lead no banco de dados ou localmente
        if (!this.lead) {
          await this.getLeadInitial(query.email || query["amp;email"]).catch(
            (err) => console.error(err)
          );
        }
        if (
          query.utility &&
          query.average_consumption &&
          query.person_type &&
          query.name &&
          query.email &&
          query.phone
        ) {
          let address = {
            address: "",
            address_number: "",
            address_complement: "",
            district: "",
            postal_code: "",
            state: {
              initials: "MG",
              name: "Minas Gerais",
            },
            city: "",
            country: {
              initials: "br",
              name: "Brasil",
            },
          };

          if (!query.address) {
            if (query.zipcode) {
              let _address = await this.buscaCep(query.zipcode);
              // console.log('_address: ', _address)
              if (_address && _address.address) address = _address;
            }
          }
          // console.log('start lead')
          let lead = {
            channel: "web",
            platform: "web",
            step: this.$route.name,
            origin_type: "direct",
          };
          if (utm)
            lead = {
              ...lead,
              ...utm,
            };
          // Verifica se veio de indicação de amigos
          let referral_code = query.referral_code;
          if (referral_code) {
            store.commit("setReferralCode", referral_code);
            lead.promo_code = referral_code;
          }
          if (query.partner && query.partner !== "")
            lead.partner = query.partner;
          lead.utility = query.utility.toLowerCase();
          lead.average_consumption = Number(query.average_consumption);
          lead.person_type = query.person_type.toLowerCase();
          lead.name = query.name;
          lead.email = query.email.toLowerCase();
          lead.phone = formatPhone(query.phone, "55");
          lead.zipcode = query.zipcode ? query.zipcode.replace(/\D/g, "") : "";
          lead.address = address;
          lead.whatsapp_optin =
            query.optin || query.optin === "on" ? true : false;
          lead.sms_optin = query.optin || query.optin === "on" ? true : false;
          lead.email_optin = query.optin || query.optin === "on" ? true : false;
          if (tracking) lead.tracking = tracking;
          // if(query.optin) {
          //   lead.whatsapp_optin = query.optin === 'on'
          //   lead.sms_optin = query.optin === 'on'
          //   lead.email_optin = query.optin === 'on'
          // }

          if (query.coupon) {
            let cupom = query.coupon.toUpperCase();
            // console.log('cupom', cupom)
            this.loading = true;
            await this.getCoupons(cupom)
              .then((res) => {
                // console.log('res: ', res)
                if (res.status) {
                  lead.coupon = store.state.coupon;
                  if(lead?.coupon?.type === "referral") {
                    lead.promo_code = lead.coupon.user
                  }
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }

          //Pega os planos disponiveis
          if (!this.plans || this.plans.length <= 0) {
            if (lead.coupon && lead.coupon.plan_id) {
              this.getPlans({
                person_type: lead.person_type,
                plan_id: lead.coupon.plan_id + lead.person_type,
                utility: lead.utility,
              });
            } else
              this.getPlans({
                person_type: lead.person_type,
                utility: lead.utility,
              });
          }

          await this.saveLead(lead)
            .then(async () => {
              this.$gtm.trackEvent({
                event: "step1_cadastro",
                category: "onboard",
                action: "click",
                label: "Cadastro na primeira pagina do onboard",
                value: lead.average_consumption,
                person_type: lead.person_type,
                email: lead.email,
                phone: lead.phone,
                noninteraction: false, // Optional
                step: this.$route.name,
              });
              convertRDStation("step1_cadastro", lead)
                .catch((err) =>
                  console.error(
                    "Erro ao gravar a conversão no RD Station. Erro: ",
                    err.error
                  )
                )
                .then(() =>
                  console.log(
                    "Conversão step1_cadastro gravada com sucesso no RD Station"
                  )
                );

              await this.$router
                .push({ path: "/simulador", query: utm })
                .catch((err) => {
                  return err;
                });
            })
            .catch((err) => {
              console.error(err);
              // this.loading = false
            });

          //store.commit('setLead', lead)
          //await this.$router.push('/simular/' + query)
        }
        // Verifica se veio da página principal
        else if (
          query.average_consumption &&
          query.person_type
        ) {
          let lead = { ...this.lead };
          lead.average_consumption = query.average_consumption;
          lead.person_type = query.person_type;
          let referral_code = query.referral_code;
          if (referral_code && referral_code !== "") {
            store.commit("setReferralCode", referral_code);
            lead.promo_code = referral_code;
          }

          store.commit("setLead", lead);
          await this.$router.push({ patch: "/simular/", query: query }).catch((err) => {
            return err;
          });
          //Pega os planos disponiveis
          if (!this.plans || this.plans.length <= 0)
            this.getPlans({
              person_type: lead.person_type,
              utility: lead.utility,
            });
          if (lead.email) {
            await this.saveLead(lead)
              .then(async () => {
                this.$gtm.trackEvent({
                  event: "step1_cadastro",
                  category: "onboard",
                  action: "click",
                  label: "Cadastro na primeira pagina do onboard",
                  person_type: lead.person_type,
                  email: lead.email,
                  phone: lead.phone,
                  value: lead.average_consumption,
                  noninteraction: false, // Optional
                  step: this.$route.name,
                });
                convertRDStation("step1_cadastro", lead)
                  .catch((err) =>
                    console.error(
                      "Erro ao gravar a conversão no RD Station. Erro: ",
                      err.error
                    )
                  )
                  .then(() =>
                    console.log(
                      "Conversão step1_cadastro gravada com sucesso no RD Station"
                    )
                  );
                await this.$router
                  .push({ path: "/simulador", query: utm })
                  .catch((err) => {
                    return err;
                  });
              })
              .catch((err) => {
                console.error(err);
                // this.loading = false
              });
          } else {
            console.error("Email não encontrado para o lead");
          }
        } else {
          await this.$router
            .push({ path: "/energialimpa", query: utm })
            .catch((err) => {
              return err;
            });
        }
        // Verifica se já possui um usuario no banco de dados ou localmente
        if (query.email && this.$route.name)
          await this.getUserInitial({
            email: query.email,
            router: this.$route.name,
          }).catch((err) => console.error(err));

        let referral_code = query.referral_code;
        if (referral_code) {
          await store.commit("setReferralCode", referral_code);
          // lead.promo_code = referral_code
        }
      }

      // Finaliza o carregamento da Página
      await this.setLoading();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapState(["enviroments"]),
  },
  methods: {
    ...mapActions([
      "getBenefits",
      "getDocConfig",
      "getLeadInitial",
      "getUserInitial",
      "saveLead",
      "getCoupons",
      "getPlans",
    ]),
    setLoading() {
      return new Promise((resolve) => resolve((this.loading = false)));
    },
    buscaCep(cep) {
      if (cep && cep !== "") {
        // this.loading_cep = true
        getCEP(cep)
          .then(async (result) => {
            let address = {
              address: result.data.logradouro || "",
              address_number: result.data.numero || "",
              address_complement: result.data.complement || "",
              district: result.data.bairro || "",
              postal_code: cep || "",
              state: {
                initials: result.data.uf || "MG",
                name: result.data.uf || "Minas Gerais",
              },
              city: result.data.localidade || "",
              country: {
                initials: "br",
                name: "Brasil",
              },
            };
            this.address = address;
            return address;
          })
          .catch((error) => {
            return error;
          });
      } else return null;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Quicksand", Graphik-Medium, Graphik-Regular, "Gotham SSm A",
    "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 5px;
  background: #fff;

  a {
    font-weight: bold;
    color: #044280;

    &.router-link-exact-active {
      color: #044280;
    }
  }
}

.form {
  background: #fff;
  min-height: 100vh;
}
</style>
