export const validExpire = (value) => {
    if(!value || value.length < 5) return false
    var today = new Date();
    let _year = today.getFullYear()
    let month = value.substring(0,2)
    if(month > 12) return false
    else month = month - 1
    let year = _year.toString().substring(0,2) + value.substring(3,5)
    var expire = new Date(year, month)

    if (expire.getTime() <= today.getTime()) {
        return false;
    }
    return true;
}

export const getCardType = (value) => {
    // visa
    let number = value.toString()
    var re = new RegExp("^4");

    if (number.match(re) != null)
        return "visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return "mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "amex";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "diners_carte_blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "jcb";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "visa_electron";

    return "";
}

export const maskCardNumber = (cardNumber) => {
    let arr = cardNumber.split('')
    arr.forEach((element, index) => {
      if (index > 4 && index < 14 && element.trim() !== '') {
        arr[index] = '*'
      }
    })
    return arr.join('')
}
