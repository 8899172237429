import api from "../utils/api";
const crypto = require("crypto");
// import pagarme from 'pagarme'

let create_customer_url = process.env.VUE_APP_PAGARME_CREATECUSTOMER;
let create_card_url = process.env.VUE_APP_PAGARME_CREATECARD;

export const createCustomer = (data) => {
  return api.post(create_customer_url, data);
};

export const cardHash = async (card) => {
  //Criptografa hash de cartão de crédito e retorna o hash
  try {
    const hashkey = await api.get(create_card_url + "/card_hash_key");
    if (hashkey.status !== 200) {
      throw new Error("Erro ao chamar api de geração de hash.");
    }
    const publicKey = hashkey.data.public_key;
    const id = hashkey.data.id;
    const queryString = `card_number=${encodeURI(
      card.card_number
    )}&card_holder_name=${encodeURI(
      card.card_holder_name
    )}&card_expiration_date=${encodeURI(
      card.card_expiration_date
    )}&card_cvv=${encodeURI(card.card_cvv)}`;
    const encrypted = crypto.publicEncrypt(
      {
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_PADDING,
      },
      Buffer.from(queryString)
    );
    return `${id}_${encrypted.toString("base64")}`;
  } catch (error) {
    return new Error("Erro ao gerar hash", error);
  }
};

export const createCard = (data) => {
  return api.post(create_card_url, data);
};
