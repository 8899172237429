export default {
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, state.getDefaultState())
    },
    setUser(state, data) {
        state.user = data
    },
    setLead(state, data) {
        state.lead = data
    },
    setToken(state, data) {
        state.token = data
    },
    setSocialToken(state, data) {
        state.socialToken = data
    },
    setEnviroments(state, data) {
        state.enviroments = data
    },
    setLandingPageConfig(state, data) {
        state.landingPageConfig = data
    },
    setUTM(state, data) {
        state.utm = data
    },
    setTracking(state, data) {
        state.tracking = data
    },
    setReferralCode(state, data) {
        state.referral_code = data
    },
    setCoupon(state, coupon) {
        state.coupon = coupon
    },
    pushQualification(state, data) {
        state.qualifications.push(data)
    },
    spliceQualification(state, { index, data }) {
        state.qualifications.splice(index, 1, data)
    },
    setQualifications(state, data) {
        state.qualifications = data
    },
    setPlans(state, data) {
        state.plans = data
    },
    setPlan(state, data) {
        state.plan = data
    },
    setUtilityCompanies(state, data) {
        state.utilityCompanies = data
    },
    pushConsumerUnit(state, data) {
        state.consumerUnits.push(data)
    },
    removeConsumerUnit(state, index) {
        state.consumerUnits.splice(index, 1);
    },
    setUserAddress(state, address) {
        state.user.address = address
    }
}
