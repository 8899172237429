<template>
    <div class="pa-0 ma-0">
        <div v-if="plan.id == 'liquidaenergiapj' || plan.id == 'liquidaenergiapf'">
            <v-img src="@/assets/contracts/capa-juntos-11.png" width="100%" />
            <div class="desc">
                <p class="my-2">{{ plan.cancelation_conditions.contract_canceled_clause }}</p>
                <p class="my-2">{{ plan.cancelation_conditions.contract_canceled_clause2 }}</p>
            </div>
            <v-img src="@/assets/contracts/capa-juntos-12.png" width="100%" />
        </div>
        <v-img v-else src="@/assets/contracts/capa-juntos-10.png" width="100%"/>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(["plan", "user"])
    }
}
</script>
<style scoped lang="scss">
.desc{
    border-radius: 30px;
    width: 90%;
    background-color: #F2F2F2;
    margin: 16px auto;
    background: #d1d2fe;
    padding: 15px 30px;
    font-size: 12px;
    color: #000;
    line-height: 1.4;
}
</style>